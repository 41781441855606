import Photo from "./Photo";
import Flex from "./Flex";
import Text from "./Text";
import { SiteTheme, ThemeContext } from "../Page";
import React from "react";
import { useParams } from "react-router-dom";

export type PhotoSize = 16 | 22 | 24 | 28 | 32 | 34 | 38 | 40 | 80 | 120;
interface UserPhotoProps {
  size: PhotoSize;
  URL?: string;
  placeholderText?: string;
  userId?: string;
}

const UserPhoto = ({
  size,
  style,
  URL,
  placeholderText,
  userId,
  ...restProps
}: UserPhotoProps & React.HTMLAttributes<HTMLImageElement>) => {
  const theme = React.useContext(ThemeContext);
  const { id } = useParams();

  const styleProps = {
    borderRadius: size / 2,
    background:
      theme === SiteTheme.purple ? "rgba(255,255,255,0.2)" : "lightgray",
    flexShrink: 0,
  };

  if (!URL && placeholderText) {
    return (
      <Flex
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: size,
          height: size,
          border: `solid 1px ${
            theme === SiteTheme.purple ? "white" : "rgba(0,0,0,0.05)"
          }`,
          ...style,
          ...styleProps,
        }}
        onClick={() => {
          if (userId && id !== userId) {
            window.open(`/profile/${userId}`, "_self");
          }
        }}
      >
        <Text
          style={{
            textTransform: "uppercase",
            color: theme === SiteTheme.purple ? "white" : "gray",
            fontSize: size < 24 ? 10 : size < 40 ? 12 : 16,
            fontWeight: 500,
          }}
        >
          {placeholderText}
        </Text>
      </Flex>
    );
  }

  return (
    <a href={userId && id !== userId ? `/profile/${userId}` : undefined}>
      <img
        height={size}
        width={size}
        src={URL}
        style={{
          ...style,
          ...styleProps,
          objectFit: "cover",
        }}
        {...restProps}
      />
    </a>
  );
};

export default UserPhoto;
