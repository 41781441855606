import React from "react";
import { Container, Offcanvas } from "react-bootstrap";
import { RepoPushIcon, ShareIcon } from "@primer/octicons-react";
import Flex from "./patterns/Flex";
import Box from "./patterns/Box";
import Text from "./patterns/Text";

import ButtonPill from "./patterns/ButtonPill";
import ModalOpener from "./modal/manager/ModalOpener";
import { ModalRegistry } from "./modal/utils";
import { StoryCharacter } from "./messages/ChatInputField";
import {
  IoChevronForward,
  IoMenu,
  IoSettingsOutline,
  IoShareOutline,
} from "react-icons/io5";
import UserPopoverInner from "./UserPopoverInner";
import { isDesktop, isMobile } from "react-device-detect";
import DropButton from "./patterns/DropButton";
import { doc, setDoc } from "firebase/firestore";
import { analytics, db } from "../utils/firebase";
import ToastMessage from "./patterns/ToastMessage";
import { logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";

export const getChatSubtitle = (characters: StoryCharacter[]) => {
  if (characters.length === 0) {
    return null;
  }

  if (characters.length === 1) {
    return characters[0].name.split(" ")[0];
  }

  if (characters.length === 2) {
    return (
      characters[0].name.split(" ")[0] +
      " and " +
      characters[1].name.split(" ")[0]
    );
  }

  if (characters.length === 3) {
    return (
      characters[0].name.split(" ")[0] +
      ", " +
      characters[1].name.split(" ")[0] +
      " and " +
      characters[2].name.split(" ")[0]
    );
  }

  if (characters.length > 3) {
    return (
      characters[0].name.split(" ")[0] +
      ", " +
      characters[1].name.split(" ")[0] +
      ", " +
      characters[2].name.split(" ")[0] +
      " and " +
      (characters.length - 3) +
      " more"
    );
  }
};

const ChatHeader = ({
  id,
  title,
  subtitle,
  characters,
  isPublished = false,
  enablePublish = false,
  readerView = true,
  showLoadingState = false,
  storyStats,
  authId,
  authName,
  authStoryCount = 0,
}: {
  id?: string;
  title?: string;
  subtitle?: string;
  characters?: StoryCharacter[];
  isPublished?: boolean;
  enablePublish?: boolean;
  readerView?: boolean;
  showLoadingState?: boolean;
  storyStats?: { title: string; number: number }[];
  authId?: string;
  authName?: string;
  authStoryCount?: number;
}) => {
  const titleToDisplay = title || "Group title";
  const subDisplay =
    subtitle || characters ? getChatSubtitle(characters!) : null;
  const [hoveredDetails, setHoveredDetails] = React.useState(false);
  const [showSideMenu, setShowSideMenu] = React.useState(false);

  const [toastInfo, setToastInfo] = React.useState<{
    show: boolean;
    message: string;
    variant: "positive" | "negative" | "neutral";
    urlToOpen?: string;
  } | null>(null);

  var titleWithoutLast: string = titleToDisplay;
  var lastWordInTitle: string | null = null;
  if (titleToDisplay.split(" ").length > 1) {
    lastWordInTitle =
      titleToDisplay.split(" ")[titleToDisplay.split(" ").length - 1];
    titleWithoutLast = titleToDisplay.substring(
      0,
      titleToDisplay.lastIndexOf(" ")
    );
  }

  const dropOptions = !isPublished
    ? [
        {
          text: "Share",
          id: "share",
        },
        {
          text: "Reader view",
          id: "preview",
        },
        {
          text: "Publish",
          id: "publish",
          emphasize: true,
        },
      ]
    : [
        {
          text: "Share",
          id: "share",
        },
        {
          text: "Reader view",
          id: "preview",
        },
        {
          text: "Unpublish",
          id: "unpublish",
          danger: true,
        },
      ];

  return (
    <>
      <Container
        style={{
          display: "flex",
          alignItems: "center",
          background: "#f9f9f9",
          borderBottom: "1px solid lightgray",
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <Box
          style={{
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => setShowSideMenu(true)}
        >
          <IoMenu size={24} color="rgba(13, 110, 253, 1)" />
        </Box>
        <Flex
          style={{
            alignItems: "center",
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <ModalOpener
            modal={
              readerView
                ? ModalRegistry.storyDetailsModal
                : ModalRegistry.editStoryModal
            }
            modalProps={{
              storyTitle: titleToDisplay,
              characters: characters,
              storyStats: storyStats,
              isPublished: readerView ? true : isPublished,
              authorId: authId,
              authName: authName,
              authStoryCount: authStoryCount,
            }}
          >
            {(options) => (
              <Box
                style={{
                  marginLeft: 2,
                  paddingRight: 6,
                  paddingLeft: 6,
                  paddingTop: 2,
                  paddingBottom: 2,
                  cursor: "pointer",
                  borderRadius: 8,
                  transitionDuration: "0.2s",
                  background: hoveredDetails ? "rgba(0,0,0,0.04)" : undefined,
                }}
                onClick={() => options.toggleModal(true)}
                onMouseOver={
                  isDesktop ? () => setHoveredDetails(true) : undefined
                }
                onMouseLeave={
                  isDesktop ? () => setHoveredDetails(false) : undefined
                }
              >
                <Flex style={{ alignItems: "center" }}>
                  <Text
                    className="max-lines-2"
                    style={
                      showLoadingState
                        ? {
                            color: "transparent",
                            background: "rgba(0,0,0,0.1)",
                            borderRadius: 100,
                          }
                        : undefined
                    }
                  >
                    {showLoadingState ? (
                      <>Loading the story title</>
                    ) : (
                      <>
                        {titleWithoutLast}{" "}
                        {lastWordInTitle ? (
                          <span style={{ whiteSpace: "nowrap" }}>
                            {lastWordInTitle}{" "}
                            <IoChevronForward
                              size={14}
                              color={showLoadingState ? "transparent" : "gray"}
                              style={{ marginBottom: 1 }}
                            />
                          </span>
                        ) : (
                          <IoChevronForward
                            size={14}
                            color="gray"
                            style={{ marginBottom: 1 }}
                          />
                        )}
                      </>
                    )}
                  </Text>
                </Flex>
                {(subDisplay || showLoadingState) && (
                  <Text
                    style={{
                      marginTop: 2,
                      fontSize: 13,
                      color: showLoadingState ? "transparent" : "gray",
                      background: showLoadingState
                        ? "rgba(0,0,0,0.05)"
                        : undefined,
                      borderRadius: showLoadingState ? 100 : undefined,
                      width: showLoadingState ? "fit-content" : undefined,
                    }}
                  >
                    {showLoadingState ? "Characters" : subDisplay}
                  </Text>
                )}
              </Box>
            )}
          </ModalOpener>
          <Flex style={{ flexShrink: 0, marginLeft: 8 }}>
            {!readerView && (
              <DropButton
                onSelect={async (option) => {
                  if (option === "share") {
                    logEvent(analytics, "story_share_click");

                    try {
                      await navigator.share({
                        title: `Read ${title} on Tiny Fiction`,
                        text: `Read ${title} on Tiny Fiction`,
                        url: window.location.href,
                      });
                    } catch (err) {
                      navigator.clipboard
                        .writeText(window.location.href)
                        .then(() => {
                          alert("Copied link!");
                        });
                    }
                  }

                  if (option === "preview") {
                    window.open(`/read/${id}`);
                  }

                  if (option === "publish" && id) {
                    logEvent(analytics, "story_publish");

                    const storyRef = doc(db, "stories", id);
                    setDoc(storyRef, { published: true }, { merge: true }).then(
                      () => {
                        setToastInfo({
                          show: true,
                          message:
                            "Successfully published; your story is now publicly viewable!",
                          variant: "positive",
                          urlToOpen: window.location.href,
                        });
                        setTimeout(() => {
                          setToastInfo({
                            show: false,
                            message:
                              "Successfully published; your story is now publicly viewable!",
                            variant: "positive",
                            urlToOpen: window.location.href,
                          });
                        }, 8000);
                      }
                    );
                  }

                  if (option === "unpublish" && id) {
                    logEvent(analytics, "story_unpublish");

                    const storyRef = doc(db, "stories", id);
                    setDoc(
                      storyRef,
                      { published: false },
                      { merge: true }
                    ).then(() => {
                      setToastInfo({
                        show: true,
                        message:
                          "Success; made story private and moved to drafts",
                        variant: "neutral",
                      });
                      setTimeout(() => {
                        setToastInfo({
                          show: false,
                          message:
                            "Success; made story private and moved to drafts",
                          variant: "neutral",
                        });
                      }, 5000);
                    });
                  }
                }}
                variant={isPublished ? "outline-secondary" : "primary"}
                size="sm"
                disabled={!enablePublish}
                items={dropOptions}
                title={
                  <Flex
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                      height: 20,
                      paddingRight: 14,
                      fontWeight: 500,
                    }}
                  >
                    {isPublished ? (
                      <>
                        <IoSettingsOutline
                          size={18}
                          style={isDesktop ? { marginRight: 4 } : undefined}
                        />
                        {isDesktop && <> Published</>}
                      </>
                    ) : (
                      <>
                        <Flex style={{ marginRight: 4 }}>
                          <RepoPushIcon size={16} />
                        </Flex>
                        {isDesktop && <> Publish</>}
                      </>
                    )}
                  </Flex>
                }
              />
            )}
            {readerView && (
              <ButtonPill
                variant="secondary"
                size="sm"
                disabled={!enablePublish}
                onClick={async () => {
                  // Trigger native share sheet
                  try {
                    await navigator.share({
                      title: `Read ${title} on Tiny Fiction`,
                      text: `Read ${title} on Tiny Fiction`,
                      url: window.location.href,
                    });
                  } catch (err) {
                    navigator.clipboard
                      .writeText(window.location.href)
                      .then(() => {
                        alert("Copied link!");
                      });
                  }
                }}
              >
                <Flex>
                  <Flex
                    style={{
                      alignItems: "center",
                      marginRight: isDesktop ? 4 : undefined,
                      height: 20,
                    }}
                  >
                    <ShareIcon size={16} />
                  </Flex>
                  {isDesktop && <> Share</>}
                </Flex>
              </ButtonPill>
            )}
          </Flex>
        </Flex>
      </Container>
      <ModalOpener
        modal={ModalRegistry.textInputModal}
        modalProps={{
          zIndex: 10002,
          placeholder:
            "Give feedback, ask a question, or make a suggestion to the team",
          title: "Send us feedback",
          textLimit: 250,
          didSubmit: (text) => {
            const loggedInUserId = getAuth().currentUser?.uid;
            const currentTime = Date.now();

            if (loggedInUserId) {
              const mesRef = doc(db, "userFeedback", loggedInUserId);

              setDoc(
                mesRef,
                {
                  [currentTime]: {
                    storyId: id,
                    note: text,
                  },
                },
                { merge: true }
              ).then(() => {
                setToastInfo({
                  show: true,
                  message: "Sent your message",
                  variant: "positive",
                });
                setTimeout(() => {
                  setToastInfo({
                    show: false,
                    message: "Sent your message",
                    variant: "positive",
                  });
                }, 5000);
              });
            } else {
              const date = Date.now().toString();
              const mesRef = doc(db, "userFeedback", date);

              setDoc(
                mesRef,

                {
                  storyId: id,
                  note: text,
                },

                { merge: true }
              ).then(() => {
                setToastInfo({
                  show: true,
                  message: "Sent your message",
                  variant: "positive",
                });
                setTimeout(() => {
                  setToastInfo({
                    show: false,
                    message: "Sent your message",
                    variant: "positive",
                  });
                }, 5000);
              });
            }
          },
          useMinHeight: true,
        }}
      >
        {(feedbackModal) => (
          <ModalOpener
            modal={ModalRegistry.signupModal}
            modalProps={{ signUp: true, zIndex: 10002 }}
          >
            {(signUpModal) => (
              <Offcanvas
                backdrop
                show={showSideMenu}
                onHide={() => setShowSideMenu(false)}
                style={{
                  minWidth: 250,
                  width: isMobile ? "85%" : "25%",
                  border: "none",
                  background: isMobile ? "#f9f9f9" : undefined,
                  zIndex: 10001,
                }}
              >
                <UserPopoverInner
                  onClose={(modalToOpen) => {
                    if (modalToOpen === "signUp") {
                      setShowSideMenu(false);
                      signUpModal.toggleModal(true);
                    } else if (modalToOpen === "sendMessage") {
                      setShowSideMenu(false);
                      feedbackModal.toggleModal(true);
                    } else {
                      setShowSideMenu(false);
                    }
                  }}
                />
              </Offcanvas>
            )}
          </ModalOpener>
        )}
      </ModalOpener>
      <ToastMessage
        show={toastInfo?.show || false}
        onExit={() => setToastInfo(null)}
        message={
          toastInfo?.message ? (
            <>
              {toastInfo.message}
              {toastInfo.urlToOpen && (
                <Flex style={{ flex: 1, marginTop: 4 }}>
                  <ButtonPill
                    variant="outline-light"
                    size="sm"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(window.location.href)
                        .then(() => {
                          alert("Copied link!");
                        });
                    }}
                  >
                    <Flex style={{ alignItems: "center" }}>
                      <Flex style={{ marginRight: 4 }}>
                        <ShareIcon size={16} />
                      </Flex>{" "}
                      Copy share link
                    </Flex>
                  </ButtonPill>
                </Flex>
              )}
            </>
          ) : (
            "Something went wrong, please try again"
          )
        }
        variant={toastInfo?.variant || "neutral"}
      />
    </>
  );
};

export default ChatHeader;
