import React from "react";

import { ModalRegistry } from "../../modal/utils";
import EditStoryModal, {
  EditStoryModalProps,
} from "../../story/EditStoryModal";
import AddCharacterModal, {
  AddCharacterModalProps,
} from "../../character/AddCharacterModal";
import RegisterModal, { RegisterModalProps } from "../../signup/RegisterModal";
import StoryDetailsModal, {
  StoryDetailsModalProps,
} from "../../story/StoryDetailsModal";
import TextInputModal, {
  TextInputModalProps,
} from "../../patterns/TextInputModal";
import MessageDetailsModal, {
  MessageDetailsModalProps,
} from "../../story/MessageDetailsModal";

export interface ModalOpenerProps {
  hasOverlay?: boolean;
  modal: ModalRegistry;
  modalProps?:
    | EditStoryModalProps
    | AddCharacterModalProps
    | RegisterModalProps
    | StoryDetailsModalProps
    | TextInputModalProps
    | MessageDetailsModalProps;
}

const ModalOpener = ({
  children,
  modal,
  modalProps,
}: {
  children(options: {
    toggleModal: (visible: boolean) => void;
  }): React.ReactNode;
} & ModalOpenerProps) => {
  const [visible, toggleModal] = React.useState(false);

  return (
    <>
      {modal === ModalRegistry.editStoryModal && visible && (
        <EditStoryModal
          key="EditStoryModal"
          {...modalProps}
          hideModal={() => toggleModal(false)}
        />
      )}
      {modal === ModalRegistry.signupModal && visible && (
        <RegisterModal
          key="RegisterModal"
          {...modalProps}
          hideModal={() => toggleModal(false)}
        />
      )}
      {modal === ModalRegistry.addCharacterModal && visible && (
        <AddCharacterModal
          key="AddCharacterModal"
          {...modalProps}
          hideModal={() => toggleModal(false)}
        />
      )}
      {modal === ModalRegistry.storyDetailsModal && visible && (
        <StoryDetailsModal
          key="StoryDetailsModal"
          {...modalProps}
          hideModal={() => toggleModal(false)}
        />
      )}
      {modal === ModalRegistry.textInputModal && visible && (
        <TextInputModal
          key="TextInputModal"
          {...modalProps}
          hideModal={() => toggleModal(false)}
        />
      )}
      {modal === ModalRegistry.messageDetailsModal && visible && (
        <MessageDetailsModal
          key="MessageDetailsModal"
          {...modalProps}
          hideModal={() => toggleModal(false)}
        />
      )}
      {children({ toggleModal })}
    </>
  );
};

export default ModalOpener;
