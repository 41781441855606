import React from "react";
import SiteNav from "./SiteNav";
import Box from "./patterns/Box";
import Flex from "./patterns/Flex";
import { Col, Container, Row } from "react-bootstrap";

export enum SiteTheme {
  regular,
  purple,
}

export const ThemeContext = React.createContext<SiteTheme>(SiteTheme.regular);

const Page = ({
  children,
  secondaryCol,
  secondaryColSize = 4,
  showNav = true,
  stickyHeader,
  stickyFooter,
  contentRef,
  pageTitle,
  theme = SiteTheme.regular,
  height,
  noOverflow = false,
}: {
  children: any;
  secondaryCol?: any;
  secondaryColSize?: number;
  showNav?: boolean;
  stickyHeader?: React.ReactElement;
  stickyFooter?: React.ReactElement;
  contentRef?: React.RefObject<HTMLDivElement>;
  pageTitle?: string;
  theme?: SiteTheme;
  height?: string | number;
  noOverflow?: boolean;
}) => {
  React.useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
  }, []);

  return (
    <ThemeContext.Provider value={theme}>
      <Flex
        style={{
          height: height || "100%",
          width: "100%",
          flexDirection: "column",
          overflow: "hidden",
          background: theme === SiteTheme.purple ? "#3A355E" : undefined,
        }}
      >
        {(stickyHeader || showNav) && (
          <Flex style={{ position: "sticky" }}>
            {stickyHeader || <SiteNav />}
          </Flex>
        )}
        <Container
          style={{
            flex: 1,
            overflowY: noOverflow ? "hidden" : "scroll",
            maxWidth: 1200,
          }}
          ref={contentRef}
        >
          <Row style={{ height: "100%" }}>
            <Col sm={12} md={12 - secondaryColSize} lg={12 - secondaryColSize}>
              {children}
            </Col>
            {secondaryCol && (
              <Col sm={12} md={secondaryColSize}>
                {secondaryCol}
              </Col>
            )}
          </Row>
        </Container>
        {stickyFooter && <Flex>{stickyFooter}</Flex>}
      </Flex>
    </ThemeContext.Provider>
  );
};

export default Page;
