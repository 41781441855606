import { initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBs-DNkNXXMhTqbuCapVatjjZKdbxtu0AQ",
  authDomain: "tinyfic.com",
  projectId: "story-press",
  storageBucket: "story-press.appspot.com",
  messagingSenderId: "418528049149",
  appId: "1:418528049149:web:4bf24446b6f52e9fe84b94",
  measurementId: "G-WNR0SG1E1W",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export default app;
