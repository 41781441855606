import React from "react";
import Page, { SiteTheme } from "../components/Page";
import Box from "../components/patterns/Box";
import Flex from "../components/patterns/Flex";
import Text from "../components/patterns/Text";
import { isDesktop } from "react-device-detect";
import { StoryCard } from "./Profile";
import CreateStoryButton from "../components/story/CreateStoryButton";
import { StoryCharacter } from "../components/messages/ChatInputField";
import { PersonalityType } from "../components/character/AddCharacterModal";
import { ChatMessage } from "./Create";
import { AUTOMATIC_MESSAGE_SEND_INTERVAL, delay } from "./Read";
import { MessageRow } from "../components/messages/MessageRow";
import demoImage from "../assets/travel.jpg";
import { SITE_NAV_HEIGHT } from "../components/SiteNav";

const Home = () => {
  const [readMessages, setReadCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const demoCharacters: StoryCharacter[] = [
    {
      name: "Kevin Gutierrez",
      personality: PersonalityType.ENFP,
      id: "kevin",
    },
    {
      name: "Taylor Hooper",
      personality: PersonalityType.INTJ,
      id: "taylor",
      isReceiver: true,
    },
  ];

  const kevin = demoCharacters[0];
  const taylor = demoCharacters[1];

  const demoMessages: ChatMessage[] = [
    {
      message: "Check out Tiny Fiction",
      character: kevin,
    },
    {
      message: "It lets you create text message stories, so cool!",
      character: kevin,
    },
    {
      message: "❗❗",
      character: taylor,
    },
    {
      message: "Woah this is awesome!",
      character: taylor,
    },
    {
      message:
        "You can use narrative text like this to explain the passage of time, things happening outside the chat, or to set context",
    },
    {
      message: "You can even use images to make the story more engaging!",
      character: kevin,
    },
    {
      message: demoImage,
      character: kevin,
      renderAsImageMessage: true,
      imageSrc: demoImage,
    },
    {
      message: "brb, I'm gonna drop some heat with this",
      character: taylor,
    },
  ];

  React.useEffect(() => {
    if (!loading) {
      delay(AUTOMATIC_MESSAGE_SEND_INTERVAL / 2).then(() => {
        sendNextMessage(readMessages + 1);
      });
    }
  }, [loading]);

  function sendNextMessage(index: number) {
    if (index > demoMessages.length) return;

    setLoading(true);
    setReadCount(index);

    // Loading time is weighted by message length, with a minimum
    const loadingTime =
      AUTOMATIC_MESSAGE_SEND_INTERVAL +
      (!demoMessages[readMessages]?.renderAsImageMessage
        ? demoMessages[readMessages].message?.toString().length || 0
        : 0) *
        15;

    delay(loadingTime).then(() => {
      setLoading(false);
    });
  }

  const getStaticMessageRow = (
    message: ChatMessage,
    idx: number,
    loading: boolean
  ) => {
    const showPhoto =
      message.character &&
      !(
        readMessages > idx + 1 &&
        message.character.id === demoMessages[idx + 1].character?.id
      );
    const showName =
      message.character &&
      !(
        idx > 0 && message.character.id === demoMessages[idx - 1].character?.id
      );

    return (
      <MessageRow
        index={idx}
        renderReaderView
        key={`message-${idx}`}
        hideButtons
        character={message.character}
        showPhoto={showPhoto}
        showName={showName}
        showLoadingDots={loading}
        renderAsImageMessage={message.renderAsImageMessage}
        imageSrc={message.imageSrc}
        renderStatic
      >
        {message.message}
      </MessageRow>
    );
  };

  const getHeader = () => {
    return (
      <Box
        style={{
          width: "100%",
          border: "1px solid lightgray",
          borderRadius: 12,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,
          background: "rgba(0,0,0,0.025)",
          marginBottom: isDesktop ? 24 : 14,
        }}
      >
        <Text
          style={{
            fontSize: 15,
            fontWeight: 600,
          }}
        >
          A demo chat story
        </Text>
        <Text variant="meta" style={{ marginTop: 4 }}>
          Kevin and Taylor
        </Text>
      </Box>
    );
  };

  return (
    <Page
      height="100vh"
      secondaryColSize={6}
      secondaryCol={
        isDesktop && (
          <Box
            style={{
              height: `calc(100vh - ${SITE_NAV_HEIGHT}px)`,
              overflow: "hidden",
              borderLeft: "1px solid lightgray",
              borderRight: "1px solid lightgray",
              position: "relative",
            }}
          >
            <Box
              style={{
                padding: 24,
                paddingBottom: 10,
              }}
            >
              {getHeader()}
              {demoMessages.slice(0, readMessages).map((message, idx) => {
                return getStaticMessageRow(
                  message,
                  idx,
                  idx === readMessages - 1 && loading
                );
              })}
            </Box>
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "25%",
                background:
                  "linear-gradient(to bottom, transparent 0%, white 100%)",
              }}
            />
          </Box>
        )
      }
    >
      <Flex
        style={{
          height: "100%",
          flexDirection: isDesktop ? "row" : "column",
          justifyContent: !isDesktop ? "space-between" : undefined,
          alignItems: isDesktop ? "center" : undefined,
        }}
      >
        <Flex
          style={{
            paddingTop: 30,
            paddingBottom: 30,
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              fontSize: isDesktop ? 48 : 36,
              fontFamily: "'Domine', serif",
              color: "black",
            }}
          >
            Introducing a new way to tell stories
          </Text>
          <Text
            style={{
              color: "black",
              marginTop: 16,
              lineHeight: 1.5,
              fontSize: isDesktop ? 18 : undefined,
            }}
          >
            Create and share your own chat-based stories, and discover a new way
            of experiencing immersive storytelling
          </Text>

          <Flex style={{ paddingTop: 24 }}>
            <CreateStoryButton
              size="lg"
              style={{ width: isDesktop ? 300 : "100%" }}
            >
              Create your story
            </CreateStoryButton>
          </Flex>
        </Flex>

        {!isDesktop && (
          <Box>
            <Box
              style={{
                position: "relative",
                height: "100vh",
                overflow: "hidden",
              }}
            >
              <Box
                style={{
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                  border: "2px solid lightgray",
                  borderBottom: "none",
                  padding: 16,
                  height: "100%",
                  overflowY: "hidden",
                }}
              >
                {getHeader()}
                {demoMessages.slice(0, readMessages).map((message, idx) => {
                  return getStaticMessageRow(
                    message,
                    idx,
                    idx === readMessages - 1 && loading
                  );
                })}
              </Box>
              <Box
                style={{
                  position: "absolute",
                  bottom: -2,
                  left: 0,
                  right: 0,
                  height: "25%",
                  background:
                    "linear-gradient(to bottom, transparent 0%, white 100%)",
                }}
              />
            </Box>
            <Flex style={{ paddingTop: 24, paddingBottom: 24 }}>
              <CreateStoryButton size="lg" style={{ width: "100%" }}>
                Create your story
              </CreateStoryButton>
            </Flex>
          </Box>
        )}
      </Flex>
    </Page>
  );
};

export default Home;
