import React from "react";

import Box from "../patterns/Box";
import Flex from "../patterns/Flex";
import {
  Container,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import DropButton from "../patterns/DropButton";
import ButtonPill from "../patterns/ButtonPill";
import {
  ChevronRightIcon,
  ImageIcon,
  NoteIcon,
  PlusIcon,
} from "@primer/octicons-react";
import { isDesktop, isMobile } from "react-device-detect";
import ModalOpener from "../modal/manager/ModalOpener";
import { ModalRegistry } from "../modal/utils";
import Text from "../patterns/Text";
import { PersonalityType } from "../character/AddCharacterModal";
import time from "../../../src/assets/time-narr.png";
import * as linkify from "linkifyjs";
import { IoBackspaceOutline } from "react-icons/io5";
import ToastMessage from "../patterns/ToastMessage";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../utils/firebase";

const INPUT_MAX_HEIGHT = 100;

export interface Character {
  name: string;
  id: string;
  bio?: string;
  photo?: string;
  from?: string;
  personality?: PersonalityType;
  birthday?: Date;
}

export interface StoryCharacter extends Character {
  isReceiver?: boolean;
}

const ChatInputField = ({
  characters,
  selected,
  disableSend = false,
  sentMessage,
  addedNarrativeText,
  reloadMessage,
  sentImage,
  selectedCharacter,
  didUndo,
  showLoadingState = false,
}: {
  characters?: StoryCharacter[];
  selected?: string;
  disableSend?: boolean;
  sentMessage?: (message: React.ReactNode[], messageStrings: string[]) => void;
  addedNarrativeText?: (message: string) => void;
  reloadMessage?: (
    message: React.ReactNode[],
    character: StoryCharacter
  ) => void;
  sentImage?: (imageSrc: string) => void;
  selectedCharacter?: (character: StoryCharacter) => void;
  didUndo?: () => void;
  showLoadingState?: boolean;
}) => {
  // Refs
  var inputRef = React.createRef<HTMLTextAreaElement>();
  var imgUploadRef = React.createRef<HTMLInputElement>();
  var addCharactersRef = React.createRef<HTMLElement>();

  const [message, setMessage] = React.useState("");
  const [lineBreaks, setLineBreaks] = React.useState<number[]>([]);
  const [showIntroTooltip, setShowTooltip] = React.useState(true);

  const [sendAs, setSendAs] = React.useState<string | null>(null);

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [showErr, setShowErr] = React.useState(false);

  React.useEffect(() => {
    if (!sendAs) {
      setShowTooltip(true && !showLoadingState);
    } else {
      setShowTooltip(false);
    }
  }, [sendAs]);

  const handleSelect = (id: any) => {
    if (
      characters &&
      characters.length > 0 &&
      characters.find((c) => c.id === id)
    ) {
      const selected = characters.find((c) => c.id === id)!;
      setSendAs(selected.id);
      selectedCharacter?.(selected);

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleSend = (): string | void => {
    if (message.length) {
      // URLs not allowed
      if (linkify.find(message).length > 0) {
        // URLs detected
        console.log("Remove URLs from the message");
        return "Links are not allowed in messages!";
      } else {
        var sections: React.ReactNode[] = [];
        var lastIndex = 0;

        var messageStrings: string[] = [];

        const strippedMessage = message.trim();
        if (lineBreaks.length > 0) {
          lineBreaks.map((breakIndex) => {
            console.log("strippedMessage.slice(lastIndex, breakIndex");
            console.log(strippedMessage.slice(lastIndex, breakIndex));
            sections.push(<>{strippedMessage.slice(lastIndex, breakIndex)}</>);
            sections.push(<br />);
            messageStrings.push(strippedMessage.slice(lastIndex, breakIndex));
            messageStrings.push("\\n");
            lastIndex = breakIndex;
          });

          if (strippedMessage.length - lastIndex > 1) {
            sections.push(
              <>{strippedMessage.slice(lastIndex, strippedMessage.length)}</>
            );
            messageStrings.push(
              strippedMessage.slice(lastIndex, strippedMessage.length)
            );
          }
        } else {
          console.log("strippedMessage");
          console.log(strippedMessage);
          sections.push(<>{strippedMessage}</>);
          messageStrings.push(strippedMessage);
        }

        console.log("messageStrings");
        console.log(messageStrings);

        sentMessage?.(sections, messageStrings);

        // Reset state variables
        setMessage("");
        setLineBreaks([]);
      }
    }

    // Automatically select input field to keep typing
    inputRef.current?.focus();
  };

  const imageReader = new FileReader();
  imageReader.onload = (e) => {
    if (e.target?.result && typeof e.target?.result === "string") {
      sentImage?.(e.target.result);

      // Automatically select input field to keep typing
      inputRef.current?.focus();
    }
  };

  const handleImageSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFile = e.target?.files?.[0];
    if (chosenFile) {
      imageReader.readAsDataURL(chosenFile);
    }
  };

  const borderStyle = "1px solid lightgray";

  return (
    <>
      <Box
        style={{
          background: "white",
          borderTop: "1px solid lightgray",
          boxShadow: "0 -2px 4px rgba(0,0,0,0.05)",
          width: "100%",
          paddingTop: 12,
          paddingBottom: 12,
        }}
      >
        <Container>
          {/* Toolbar */}
          <Flex>
            {/* Lefthand actions */}
            <Flex
              style={{
                flex: 1,
                alignItems: "center",
                paddingRight: 4,
                minWidth: 0,
              }}
            >
              <ModalOpener modal={ModalRegistry.addCharacterModal}>
                {(options) => (
                  <>
                    {characters && characters.length > 0 ? (
                      <DropButton
                        onSelect={handleSelect}
                        size="sm"
                        variant="secondary"
                        style={{ minWidth: 0 }}
                        title={
                          <Box
                            style={{
                              alignItems: "center",
                              marginRight: 16,
                              fontWeight: 500,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {`as: ${
                              characters.find(
                                (c) => c.id === (selected || sendAs)
                              )?.name
                            }`}
                          </Box>
                        }
                        items={characters.map((c) => {
                          return {
                            text: c.name,
                            id: c.id,
                            active: (selected || sendAs) === c.id || false,
                          };
                        })}
                        appendButton={{
                          content: (
                            <Flex
                              style={{
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <b style={{ fontWeight: 500 }}>Add</b>
                              <ChevronRightIcon size={16} />
                            </Flex>
                          ),
                          onClick: () => options.toggleModal(true),
                        }}
                      />
                    ) : (
                      <OverlayTrigger
                        show={showIntroTooltip}
                        placement="top"
                        overlay={
                          <Tooltip>
                            Add characters to start writing your story
                          </Tooltip>
                        }
                      >
                        <ButtonPill
                          size="sm"
                          variant="primary"
                          onClick={() => options.toggleModal(true)}
                          ref={addCharactersRef}
                          disabled={showLoadingState}
                        >
                          <PlusIcon size={14} /> Add character
                        </ButtonPill>
                      </OverlayTrigger>
                    )}
                  </>
                )}
              </ModalOpener>
            </Flex>
            {/* Righthand actions */}
            <Flex style={{ flexShrink: 0, alignItems: "center" }}>
              <ButtonPill
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  didUndo?.();
                  logEvent(analytics, "story_message_undo");
                }}
                disabled={disableSend}
              >
                <Flex style={{ alignItems: "center" }}>
                  <IoBackspaceOutline size={20} style={{ marginRight: 4 }} />{" "}
                  Unsend last
                </Flex>
              </ButtonPill>
            </Flex>
          </Flex>
          <FormControl
            ref={inputRef}
            size={isMobile ? "sm" : undefined}
            placeholder="Type a message..."
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setLineBreaks([...lineBreaks, message.length]);
              }
            }}
            value={message}
            as="textarea"
            disabled={disableSend}
            style={{
              maxHeight: INPUT_MAX_HEIGHT,
              borderRadius: 10,
              background: "white",
              borderTop: borderStyle,
              borderLeft: borderStyle,
              borderRight: borderStyle,
              borderBottom: "none",
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              padding: 8,
              marginTop: 8,
            }}
          />
          {/* Toolbar */}
          <Flex
            style={{
              flex: 1,
              padding: 8,
              border: borderStyle,
              borderBottomRightRadius: 10,
              borderBottomLeftRadius: 10,
              background: "rgba(0,0,0,0.05)",
            }}
          >
            {/* Lefthand actions */}
            <Flex
              style={{
                flex: 1,
                alignItems: "center",
                paddingRight: 10,
                minWidth: 0,
              }}
            >
              <ModalOpener
                modal={ModalRegistry.textInputModal}
                modalProps={{
                  title: "Add narrative text",
                  subtitle:
                    "Narrative text adds context to your story. It can be used to show the passage of time, internal dialogue, or events happening outside the chat.",
                  textLimit: 300,
                  didSubmit: (text) => {
                    addedNarrativeText?.(text);
                  },
                  useMinHeight: true,
                }}
              >
                {(options) => (
                  <ButtonPill
                    size="sm"
                    variant="secondary"
                    style={{ marginRight: 4 }}
                    disabled={disableSend}
                    onClick={() => options.toggleModal(true)}
                  >
                    <NoteIcon size={14} />
                  </ButtonPill>
                )}
              </ModalOpener>
              <ButtonPill
                size="sm"
                variant="secondary"
                disabled={disableSend}
                onClick={() => {
                  if (imgUploadRef.current) {
                    imgUploadRef.current.click();
                  }
                }}
              >
                <input
                  ref={imgUploadRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageSelection(e)}
                />
                <ImageIcon size={14} />
              </ButtonPill>
            </Flex>

            {/* Righthand actions */}
            <Flex style={{ flexShrink: 0, alignItems: "center" }}>
              <ButtonPill
                size="sm"
                variant="primary"
                onClick={() => {
                  const err = handleSend();
                  if (err) {
                    setErrorMessage(err);
                    setShowErr(true);
                    setTimeout(() => {
                      setShowErr(false);
                    }, 3000);
                  } else {
                    logEvent(analytics, "story_message_created");
                  }
                }}
                disabled={disableSend || message.length === 0}
              >
                Send
              </ButtonPill>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <ToastMessage
        show={showErr}
        onExit={() => setErrorMessage(null)}
        message={errorMessage || "Something went wrong, please try again"}
        variant="negative"
      />
    </>
  );
};

export default ChatInputField;
