import React from "react";
import Box from "../patterns/Box";
import Flex from "../patterns/Flex";
import Text from "../patterns/Text";
import Modal, { ModalProps } from "../modal/Modal";
import google from "../../../src/assets/google.png";
import { doc, setDoc } from "firebase/firestore";
import { analytics, db } from "../../utils/firebase";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  updateProfile,
} from "firebase/auth";
import { Form } from "react-bootstrap";
import { logEvent } from "firebase/analytics";

export interface RegisterModalProps extends ModalProps {
  signUp?: boolean;
}

function getSignupErrorMessage(code: string): string {
  console.log(code);
  if (code.includes("invalid-email")) {
    return "Invalid email";
  }
  if (code.includes("email-already-in-use")) {
    return "Email is already registered, try signing in";
  }
  if (code.includes("invalid-display-name")) {
    return "Invalid display name";
  }
  if (code.includes("weak-password")) {
    return "Invalid password, must be at least 6 characters";
  }
  return "Something went wrong, please try again";
}

const RegisterModal = ({ signUp = true, ...restProps }: RegisterModalProps) => {
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [name, setName] = React.useState("");
  const [err, setErr] = React.useState<any | undefined>(undefined);
  const [state, setState] = React.useState<"signUp" | "signIn">(
    signUp ? "signUp" : "signIn"
  );

  const provider = new GoogleAuthProvider();

  React.useEffect(() => {
    logEvent(analytics, "signup_modal_open");
  }, []);

  return (
    <Modal
      title={state === "signUp" ? "Sign up" : "Welcome back!"}
      subtitle={
        state === "signUp"
          ? "Create an account to start writing chat stories"
          : undefined
      }
      primaryButtonProps={{
        disableAutomaticSubmit: true,
        buttonProps: {
          text: state === "signUp" ? "Next" : "Sign in",
          disabled:
            state === "signUp" ? !(email && pass && name) : !(email && pass),
          onClick: () => {
            // this should be used for the email/pass signup
            // TODO: Brian check is currently in signIn or signUp state

            if (email && pass) {
              const auth = getAuth();
              createUserWithEmailAndPassword(auth, email, pass)
                .then((userCredential) => {
                  // Signed in
                  const user = userCredential.user;
                  updateProfile(user, { displayName: name });

                  const email = user.email;
                  const uid = user.uid;

                  if (state === "signUp") {
                    const profileRef = doc(db, "userProfiles", user.uid);
                    setDoc(
                      profileRef,
                      {
                        name: name,
                        displayName: name,
                        totalStories: 0,
                        totalLove: 0,
                        totalFollowers: 0,
                        totalViews: 0,
                      },
                      { merge: true }
                    )
                      .then(() => {
                        window.open(`/profile/${uid}`, "_self");
                      })
                      .catch((error) => {
                        setErr(getSignupErrorMessage(error.code));
                      });
                  } else {
                    window.open(`/profile/${uid}`, "_self");
                  }

                  // TODO: Brian Create random username
                  // TODO: Brian Save user info to database.
                })
                .catch((error) => {
                  setErr(getSignupErrorMessage(error.code));
                });
            }
          },
        },
      }}
      {...restProps}
    >
      <Box style={{ width: "100%" }}>
        <Flex
          style={{
            height: 40,
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: 100,
            cursor: "pointer",
            background: "#f9f9f9",
          }}
          onClick={() => {
            const auth = getAuth();
            signInWithPopup(auth, provider)
              .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential =
                  GoogleAuthProvider.credentialFromResult(result);
                const token = credential!.accessToken;
                // The signed-in user info.
                const user = result.user;
                const email = user.email;
                const uid = user.uid;

                // Create the account
                if (state === "signUp") {
                  const profileRef = doc(db, "userProfiles", user.uid);
                  setDoc(
                    profileRef,
                    {
                      name: name,
                      displayName: name,
                      totalStories: 0,
                      totalLove: 0,
                      totalFollowers: 0,
                      totalViews: 0,
                    },
                    { merge: true }
                  )
                    .then(() => {
                      window.open(`/profile/${uid}`, "_self");
                    })
                    .catch((error) => {
                      setErr(getSignupErrorMessage(error.code));
                    });
                } else {
                  window.open(`/profile/${uid}`, "_self");
                }
              })
              .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential =
                  GoogleAuthProvider.credentialFromError(error);
                // ...
              });
          }}
        >
          <Flex style={{ alignItems: "center" }}>
            <img
              src={google}
              style={{ width: 24, height: 24, marginRight: 6 }}
            />
            <Text style={{ fontSize: 16, fontWeight: 400 }}>
              {state === "signUp" ? (
                <>Sign up with Google</>
              ) : (
                <>Sign in with Google</>
              )}
            </Text>
          </Flex>
        </Flex>
        <Flex
          style={{
            flex: 1,
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <hr style={{ flex: 1, color: "gray" }} />
          <Text variant="meta" style={{ marginLeft: 10, marginRight: 10 }}>
            or
          </Text>
          <hr style={{ flex: 1, color: "gray" }} />
        </Flex>

        <Form>
          {state === "signUp" && (
            <>
              <Text variant="meta" style={{ marginBottom: 4 }}>
                Display name
              </Text>
              <Form.Control
                key="name"
                placeholder="John Doe"
                style={{ marginBottom: 16 }}
                value={name}
                onChange={(e) => {
                  setErr(undefined);
                  setName(e.target.value);
                }}
              />
            </>
          )}
          <Text variant="meta" style={{ marginBottom: 4 }}>
            Email
          </Text>
          <Form.Control
            key="email"
            type="email"
            placeholder="hey@domain.com"
            style={{ marginBottom: 16 }}
            value={email}
            onChange={(e) => {
              setErr(undefined);
              setEmail(e.target.value);
            }}
          />
          <Text variant="meta" style={{ marginBottom: 4 }}>
            Password
          </Text>
          <Form.Control
            key="password"
            type="password"
            placeholder="Password"
            value={pass}
            onChange={(e) => {
              setErr(undefined);
              setPass(e.target.value);
            }}
          />
        </Form>
        {err && (
          <Text variant="meta" style={{ marginTop: 8, color: "red" }}>
            {err}
          </Text>
        )}
        <Text style={{ marginTop: 16, fontSize: 13 }}>
          {state === "signUp" ? (
            <>
              Already have an account?{" "}
              <a
                onClick={() => setState("signIn")}
                style={{ color: "blue", cursor: "pointer" }}
              >
                Sign in
              </a>
            </>
          ) : (
            <>
              Don't have an account yet?{" "}
              <a
                onClick={() => setState("signUp")}
                style={{ color: "blue", cursor: "pointer" }}
              >
                Sign up
              </a>
            </>
          )}
        </Text>
      </Box>
    </Modal>
  );
};

export default RegisterModal;
