import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { IoChevronForward } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
import CharacterListItem from "../character/CharacterListItem";
import { StoryCharacter } from "../messages/ChatInputField";
import { getUserPhotoPlaceholderString } from "../messages/MessageRow";
import Modal, { ModalProps } from "../modal/Modal";
import Box from "../patterns/Box";
import ButtonPill from "../patterns/ButtonPill";
import Text from "../patterns/Text";
import UserPhoto from "../patterns/UserPhoto";
import { PaneDetailRow } from "../UserPopoverInner";

export interface StoryDetailsModalProps extends ModalProps {
  authorId?: string;
  authName?: string;
  authStoryCount?: number;
  storyTitle?: string;
  characters?: StoryCharacter[];
}

const StoryDetailsModal = ({
  authorId,
  authName,
  authStoryCount = 0,
  storyTitle,
  characters,
  isVisible,
  ...restProps
}: StoryDetailsModalProps) => {
  const [sorted, setSorted] = React.useState<StoryCharacter[]>([]);
  const [authorName, setName] = React.useState(authName);
  const [authorUid, setAuthId] = React.useState(authorId);
  const [stories, setStories] = React.useState(authStoryCount);

  const [loading, setLoading] = React.useState(true);

  const { id } = useParams();

  React.useEffect(() => {
    if (characters) {
      var sortedArr = characters;
      sortedArr.sort((a, b) => Number(b.isReceiver) - Number(a.isReceiver));
      setSorted(sortedArr);
    }

    // Get author info
    if (id) {
      if (!authorId && (!authName || !authStoryCount)) {
        const storyRef = doc(db, "stories", id);
        getDoc(storyRef).then((snap) => {
          const author = snap.data()?.author;
          if (author) {
            const authorRef = doc(db, "userProfiles", author);
            setAuthId(author);
            getDoc(authorRef).then((snap) => {
              const name = snap.data()?.displayName;
              const authStories = snap.data()?.totalStories;
              setName(name);
              if (authStories > 1) {
                setStories(authStories - 1);
              }
              setLoading(false);
            });
          }
        });
      } else if (authorId && (!authName || !authStoryCount)) {
        const authorRef = doc(db, "userProfiles", authorId);
        setAuthId(authorId);
        getDoc(authorRef).then((snap) => {
          const name = snap.data()?.displayName;
          const authStories = snap.data()?.totalStories;
          setName(name);
          if (authStories > 1) {
            setStories(authStories - 1);
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <Modal title={storyTitle} {...restProps}>
      <Box style={{ width: "100%" }}>
        <Text variant="meta">Author</Text>
        <PaneDetailRow
          loading={loading}
          icon={
            <UserPhoto
              size={40}
              placeholderText={
                authorName
                  ? getUserPhotoPlaceholderString(authorName)
                  : undefined
              }
            />
          }
          text={authorName}
          subText={
            stories > 0
              ? `${stories} other ${stories === 1 ? "story" : "stories"}`
              : "No other stories"
          }
          style={{
            marginLeft: -16,
            marginRight: -16,
            paddingTop: 8,
            paddingLeft: 16,
            paddingRight: 16,
          }}
          attachmentElement={
            authorUid ? (
              <ButtonPill
                size="sm"
                variant="outline-secondary"
                onClick={
                  authorUid
                    ? () => window.open(`/profile/${authorUid}`, "_self")
                    : undefined
                }
              >
                View profile
              </ButtonPill>
            ) : undefined
          }
        />

        <Text
          variant="meta"
          style={{ marginBottom: 4, marginTop: authorName ? 24 : undefined }}
        >
          Characters
        </Text>
        <Box
          style={{
            marginLeft: -16,
            marginRight: -16,
            borderTop: "1px solid lightgray",
            background: "rgba(0,0,0,0.01)",
          }}
        >
          {sorted?.map((c) => (
            <CharacterListItem character={c} noBackground />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};

export default StoryDetailsModal;
