import React from "react";

import Flex from "../patterns/Flex";
import Text from "../patterns/Text";
import UserPhoto from "../patterns/UserPhoto";
import { StoryCharacter } from "./ChatInputField";
import DotLoader from "./utils/DotLoader";
import {
  CommentDiscussionIcon,
  HeartFillIcon,
  HeartIcon,
} from "@primer/octicons-react";
import { isDesktop } from "react-device-detect";
import { ModalRegistry } from "../modal/utils";
import ModalOpener from "../modal/manager/ModalOpener";
import { ChatMessage } from "../../pages/Create";
import { getAuth } from "firebase/auth";
import Box from "../patterns/Box";

const HOTIZONTAL_PADDING = 12;
const VERTICAL_PADDING = 8;
const PHOTO_SIZE = 32;
const BORDER_RADIUS = 18;
const BORDER_RADIUS_SMALL = 4;
const MESSAGE_MIN_HEIGHT = 36;
const MESSAGE_FONT_SIZE = 17;

const IMAGE_MAX_WIDTH = isDesktop ? "400px" : "60vw";
const IMAGE_MAX_HEIGHT = "60vh";

const RECEIVED_MESSAGE_BG = "rgba(0,0,0,0.05)";
const SENT_MESSAGE_BG = "rgba(13, 110, 253, 1)";

export const getUserPhotoPlaceholderString = (name: string) => {
  const firstTwoWords = name.split(" ").slice(0, 2);
  return firstTwoWords
    .map((word) => word[0])
    .join("")
    .replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "");
};

const TruncatedMessage = ({
  character,
  isReceiver = false,
  children,
  imageSrc,
}: {
  character: StoryCharacter;
  isReceiver?: boolean;
  children?: any;
  imageSrc?: string;
}) => {
  return (
    <>
      {!isReceiver && <MessagePhoto character={character} />}
      <Flex
        style={{
          marginLeft: !isReceiver ? 6 : undefined,
          marginRight: isReceiver ? 6 : undefined,
          flexDirection: "column",

          flex: 1,
        }}
      >
        <Text
          variant="meta"
          style={{
            textAlign: isReceiver ? "right" : "left",
            marginBottom: 4,
            lineHeight: 1,
            marginLeft: !isReceiver ? HOTIZONTAL_PADDING : undefined,
            marginRight: isReceiver ? HOTIZONTAL_PADDING : undefined,
          }}
        >
          {character.name}
        </Text>
        <Flex
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: isReceiver ? "flex-end" : "flex-start",
            alignItems: "center",
          }}
        >
          <Message
            character={character}
            isReceiver={isReceiver}
            truncate
            renderAsImageMessage={!!imageSrc}
            imageSrc={imageSrc}
          >
            {children}
          </Message>
        </Flex>
      </Flex>
      {isReceiver && <MessagePhoto character={character} />}
    </>
  );
};

const MessagePhoto = ({ character }: { character: StoryCharacter }) => (
  <Flex style={{ flexDirection: "column" }}>
    <Flex style={{ flex: 1 }} />
    <UserPhoto
      size={PHOTO_SIZE}
      placeholderText={getUserPhotoPlaceholderString(character.name)}
      URL={character.photo}
    />
  </Flex>
);

const Message = ({
  character,
  renderAsImageMessage = false,
  imageSrc,
  showLoadingDots = false,
  isReceiver = false,
  showPhoto = true,
  renderReaderView = true,
  commentsCount = 0,
  children,
  truncate = false,
  didSubmitComment,
  didOpenMessageModal,
  index,
  renderStatic = false,
  blur = false,
}: {
  character: StoryCharacter;
  renderAsImageMessage?: boolean;
  imageSrc?: string;
  showLoadingDots?: boolean;
  isReceiver?: boolean;
  showPhoto?: boolean;
  renderReaderView?: boolean;
  commentsCount?: number;
  children?: any;
  truncate?: boolean;
  didSubmitComment?: (comment: string) => void;
  didOpenMessageModal?: () => void;
  index?: number;
  renderStatic?: boolean;
  blur?: boolean;
}) => {
  const [numComms, setNumComms] = React.useState(commentsCount);

  const padding = !renderAsImageMessage || showLoadingDots;
  return (
    <ModalOpener
      modal={ModalRegistry.messageDetailsModal}
      modalProps={{
        message: (
          <TruncatedMessage
            character={character}
            isReceiver={isReceiver}
            imageSrc={imageSrc}
          >
            {children}
          </TruncatedMessage>
        ),
        didSubmitComment(comment) {
          setNumComms(numComms + 1);
          didSubmitComment?.(comment);
        },
        messageIndex: index,
        renderReaderView: renderReaderView,
      }}
    >
      {(options) => (
        <Flex
          style={{
            background:
              isReceiver && (!renderAsImageMessage || showLoadingDots)
                ? SENT_MESSAGE_BG
                : RECEIVED_MESSAGE_BG,
            borderTopLeftRadius: BORDER_RADIUS,
            borderTopRightRadius: BORDER_RADIUS,
            paddingLeft: padding ? HOTIZONTAL_PADDING : undefined,
            paddingRight: padding ? HOTIZONTAL_PADDING : undefined,
            paddingTop: padding ? VERTICAL_PADDING : undefined,
            paddingBottom: padding ? VERTICAL_PADDING : undefined,
            borderBottomLeftRadius:
              showPhoto && !isReceiver ? BORDER_RADIUS_SMALL : BORDER_RADIUS,
            borderBottomRightRadius:
              showPhoto && isReceiver ? BORDER_RADIUS_SMALL : BORDER_RADIUS,
            height: "fit-content",
            width: "fit-content",
            minHeight: MESSAGE_MIN_HEIGHT,
            overflow: "hidden",
            flexDirection: "column",
            justifyContent: "center",
            cursor: !truncate && !renderStatic ? "pointer" : undefined,
          }}
          onClick={() => {
            if (!truncate && !renderStatic) {
              options.toggleModal(true);
              didOpenMessageModal?.();
            }
          }}
        >
          {showLoadingDots ? (
            <DotLoader color={isReceiver ? "white" : "black"} />
          ) : (
            <>
              {renderAsImageMessage ? (
                <>
                  {imageSrc ? (
                    <Box style={{ position: "relative" }}>
                      <img
                        src={imageSrc}
                        style={{
                          maxWidth: IMAGE_MAX_WIDTH,
                          maxHeight: IMAGE_MAX_HEIGHT,
                          height: "auto",
                          objectFit: "cover",
                          filter: blur ? "blur(3px)" : undefined,
                        }}
                      />
                      {numComms > 0 && (
                        <Flex
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            alignItems: "center",
                            marginTop: 4,
                            padding: HOTIZONTAL_PADDING,
                            background:
                              "linear-gradient(to bottom, transparent 0%, black 100%)",
                          }}
                        >
                          <CommentDiscussionIcon size={14} fill="white" />
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: 600,
                              color: "white",
                              marginLeft: 4,
                            }}
                          >
                            {numComms}
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  ) : (
                    <Flex
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 10,
                      }}
                    >
                      <Text variant="meta">Loading image...</Text>
                    </Flex>
                  )}
                </>
              ) : (
                <>
                  <Text
                    className={truncate ? "max-lines-2" : undefined}
                    style={{
                      color: isReceiver ? "white" : "black",
                      wordBreak: "break-word",
                      fontSize: MESSAGE_FONT_SIZE,
                      filter: blur ? "blur(3px)" : undefined,
                    }}
                  >
                    {children}
                  </Text>
                  {numComms > 0 && (
                    <Flex style={{ alignItems: "center", marginTop: 4 }}>
                      <CommentDiscussionIcon
                        size={14}
                        fill={isReceiver ? "rgba(255,255,255,0.75)" : "gray"}
                      />
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: isReceiver ? "rgba(255,255,255,0.75)" : "gray",
                          marginLeft: 4,
                        }}
                      >
                        {numComms}
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </>
          )}
        </Flex>
      )}
    </ModalOpener>
  );
};

export const MessageRow = ({
  children,
  character,
  showName = true,
  showPhoto = true,
  hideButtons = false,
  renderReaderView = false,
  likes = 0,
  commentsCount = 0,
  isLiked = false,
  showLoadingDots = false,
  renderAsImageMessage = false,
  imageSrc,
  mb = true,
  didLikeMessage,
  didAttemptLike,
  didSubmitComment,
  didOpenMessageModal,
  index,
  renderStatic = false,
  blur = false,
}: {
  children: any;
  character?: StoryCharacter;
  showName?: boolean;
  showPhoto?: boolean;
  hideButtons?: boolean;
  renderReaderView?: boolean;
  likes?: number;
  commentsCount?: number;
  isLiked?: boolean;
  showLoadingDots?: boolean;
  renderAsImageMessage?: boolean;
  imageSrc?: string;
  mb?: boolean;
  didLikeMessage?: (numLikes: number) => void;
  didAttemptLike?: () => void;
  didSubmitComment?: (comment: string) => void;
  didOpenMessageModal?: () => void;
  index?: number;
  renderStatic?: boolean;
  blur?: boolean;
}) => {
  const isReceiver = character?.isReceiver || false;
  const renderAsNarrativeText = !character;

  const MessageLikes = () => {
    const [liked, setLiked] = React.useState(isLiked);
    const [numLikes, setNumLikes] = React.useState(likes);

    // Do not show actions until message is loaded
    if (showLoadingDots || hideButtons || renderStatic) return null;

    return (
      <>
        {!isReceiver && <Flex style={{ flex: 1 }} />}
        <Flex
          style={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginLeft: !isReceiver ? 10 : undefined,
            marginRight: isReceiver ? 10 : undefined,
            height: MESSAGE_MIN_HEIGHT,
          }}
        >
          <Flex
            onClick={() => {
              const user = getAuth().currentUser?.uid;
              if (user) {
                if (!liked) {
                  setNumLikes(numLikes + 1);
                  didLikeMessage?.(1);
                } else {
                  setNumLikes(numLikes - 1);
                  didLikeMessage?.(-1);
                }

                setLiked(!liked);
              } else {
                didAttemptLike?.();
              }
            }}
            style={{
              cursor: "pointer",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {liked ? (
              <HeartFillIcon size={16} fill="red" />
            ) : (
              <HeartIcon size={16} fill="gray" />
            )}

            {numLikes > 0 && (
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: "gray",
                  marginTop: 2,
                }}
              >
                {numLikes}
              </Text>
            )}
          </Flex>
        </Flex>
        {isReceiver && <Flex style={{ flex: 1 }} />}
      </>
    );
  };

  const renderAsMessage = () => {
    if (!character) return null;

    return (
      <>
        {showPhoto && !isReceiver && <MessagePhoto character={character} />}
        <Flex
          style={{
            marginLeft:
              !isReceiver && showPhoto
                ? 6
                : !isReceiver && !showPhoto
                ? PHOTO_SIZE + 6
                : undefined,
            marginRight:
              isReceiver && showPhoto
                ? 6
                : isReceiver && !showPhoto
                ? PHOTO_SIZE + 6
                : undefined,
            flexDirection: "column",

            flex: 1,
          }}
        >
          {showName && (
            <Text
              variant="meta"
              style={{
                textAlign: isReceiver ? "right" : "left",
                marginBottom: 4,
                lineHeight: 1,
                marginLeft: !isReceiver ? HOTIZONTAL_PADDING : undefined,
                marginRight: isReceiver ? HOTIZONTAL_PADDING : undefined,
              }}
            >
              {character.name}
            </Text>
          )}
          <Flex
            style={{
              flexDirection: "row",
              flex: 1,
              justifyContent: isReceiver ? "flex-end" : "flex-start",
              alignItems: "center",
            }}
          >
            {isReceiver && renderReaderView && <MessageLikes />}
            <Message
              character={character}
              renderAsImageMessage={renderAsImageMessage}
              imageSrc={imageSrc}
              showLoadingDots={showLoadingDots}
              renderReaderView={renderReaderView}
              showPhoto={showPhoto}
              commentsCount={commentsCount}
              isReceiver={isReceiver}
              didSubmitComment={didSubmitComment}
              didOpenMessageModal={didOpenMessageModal}
              index={index}
              renderStatic={renderStatic}
              blur={blur}
            >
              {children}
            </Message>
            {!isReceiver && renderReaderView && <MessageLikes />}
          </Flex>
        </Flex>
        {showPhoto && isReceiver && <MessagePhoto character={character} />}
      </>
    );
  };

  return (
    <Flex
      style={{ marginBottom: !mb ? 0 : !showPhoto ? 1 : 14, width: "100%" }}
    >
      {renderAsNarrativeText ? (
        <Text
          variant="meta"
          style={{
            textAlign: "center",
            marginLeft: 30,
            marginRight: 30,
            width: "100%",
            fontWeight: 500,
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 13,
          }}
        >
          {children}
        </Text>
      ) : (
        renderAsMessage()
      )}
    </Flex>
  );
};
