import React from "react";
import Flex from "../patterns/Flex";
import UserPhoto from "../patterns/UserPhoto";
import Text from "../patterns/Text";
import Box from "../patterns/Box";
import { getUserPhotoPlaceholderString } from "../messages/MessageRow";
import { StoryComment } from "./MessageDetailsModal";
import { getShortDateString } from "../../utils/date";
import { HeartFillIcon, HeartIcon } from "@primer/octicons-react";
import { getAuth } from "firebase/auth";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../utils/firebase";

interface StoryCommentItemProps {
  isLiked?: boolean;
  comment?: StoryComment;
  noSeparator?: boolean;
  paddingBottom?: boolean;
  actionable?: boolean;
  didLikeComment?: (likeChange: number) => void;
  didAttemptLike?: () => void;
}

const StoryCommentItem = ({
  isLiked = false,
  comment,
  noSeparator = false,
  paddingBottom = true,
  actionable = true,
  didLikeComment,
  didAttemptLike,
}: StoryCommentItemProps) => {
  const [liked, setLiked] = React.useState(isLiked);
  const [numLikes, setNumLikes] = React.useState(comment?.likes || 0);

  return (
    <Flex
      style={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: !paddingBottom ? undefined : 16,
        borderBottom: noSeparator ? undefined : "1px solid lightgray",
        background: "white",
      }}
    >
      <UserPhoto
        size={28}
        URL={comment?.authorProfilePhoto}
        placeholderText={
          comment?.name
            ? getUserPhotoPlaceholderString(comment?.name)
            : undefined
        }
      />
      <Box style={{ marginLeft: 10, flex: 1 }}>
        <Flex style={{ alignItems: "center" }}>
          <Text
            style={{
              color: comment ? "black" : "transparent",
              fontWeight: 600,
              fontSize: 13,
              background: !comment ? "rgba(0,0,0,0.1)" : undefined,
              borderRadius: !comment ? 100 : undefined,
            }}
          >
            {comment?.name || "Loading name"}
          </Text>
        </Flex>

        <Flex
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 4,
            flex: 1,
          }}
        >
          <Flex style={{ flex: 1, flexDirection: "column" }}>
            <Text
              style={
                !comment
                  ? {
                      color: "transparent",
                      background: "rgba(0,0,0,0.05)",
                      borderRadius: 100,
                    }
                  : undefined
              }
            >
              {comment?.commentText || "Loading comment text here"}
            </Text>
            <Flex
              style={{
                alignItems: "center",
                justifyContent: actionable ? "space-between" : undefined,
                marginTop: 8,
              }}
            >
              <Text
                variant="meta"
                style={{
                  fontSize: 12,
                  color: "gray",
                }}
              >
                {comment?.time && getShortDateString(comment.time)}
              </Text>
              {actionable && (
                <Flex
                  style={{ alignItems: "center", cursor: "pointer" }}
                  onClick={() => {
                    const user = getAuth().currentUser?.uid;
                    if (user) {
                      if (!liked) {
                        setNumLikes(numLikes + 1);
                        didLikeComment?.(1);
                      } else {
                        setNumLikes(numLikes - 1);
                        didLikeComment?.(-1);
                      }

                      setLiked(!liked);
                    } else {
                      didAttemptLike?.();
                    }
                  }}
                >
                  {numLikes > 0 && (
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        color: "gray",
                        marginRight: 6,
                      }}
                    >
                      {numLikes}
                    </Text>
                  )}

                  {liked && comment ? (
                    <HeartFillIcon size={16} fill="red" />
                  ) : comment ? (
                    <HeartIcon size={16} fill="gray" />
                  ) : null}
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default StoryCommentItem;
