import { Button, ButtonProps } from "react-bootstrap";
import React from "react";

const ButtonPill = React.forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const { style, ...restProps } = props;
  return (
    <Button
      style={{
        fontWeight: 500,
        ...style,
      }}
      {...restProps}
      className="rounded-pill"
      ref={ref as React.RefObject<HTMLElement>}
    />
  );
});

export default ButtonPill;
