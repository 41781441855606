import { BoxProps } from "./Box";
import Flex from "./Flex";
import Text from "./Text";

export interface TabProps {
  title: string;
  active?: boolean;
  id: string;
  onClick?: () => void;
}

export interface TabContainerProps extends BoxProps {
  tabs: TabProps[];
}

export const Tab = ({ title, active = false, onClick }: TabProps) => {
  return (
    <Flex
      onClick={onClick}
      style={{
        borderBottom: active ? "2px solid rgba(13, 110, 253, 1)" : undefined,
        paddingTop: 8,
        paddingBottom: 8,
        marginRight: 10,
        cursor: "pointer",
      }}
    >
      <Text
        style={{
          fontWeight: 500,
          fontSize: 13,
          color: active ? "rgba(13, 110, 253, 1)" : "gray",
        }}
      >
        {title}
      </Text>
    </Flex>
  );
};

const TabContainer = ({ tabs, ...restProps }: TabContainerProps) => {
  return (
    <Flex {...restProps}>
      {tabs.map((tab) => (
        <Tab key={tab.id} {...tab} />
      ))}
    </Flex>
  );
};

export default TabContainer;
