import React from "react";

import { isDesktop } from "react-device-detect";
import Page from "../components/Page";
import ChatHeader from "../components/ChatHeader";
import ChatInputField, {
  StoryCharacter,
} from "../components/messages/ChatInputField";
import { MessageRow } from "../components/messages/MessageRow";
import Flex from "../components/patterns/Flex";
import Box from "../components/patterns/Box";
import Text from "../components/patterns/Text";
import { Container } from "react-bootstrap";
import { analytics, db, storage } from "../utils/firebase";
import { getAuth } from "firebase/auth";
import {
  collection,
  onSnapshot,
  doc,
  getDocs,
  DocumentReference,
  DocumentData,
  query,
  where,
  documentId,
  setDoc,
  deleteField,
  getDoc,
  increment,
} from "firebase/firestore";
import CharacterListItem from "../components/character/CharacterListItem";
import { getChatMessageArrayFromFirebase } from "./Read";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { useParams } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { StatsInner } from "../components/story/EditStoryModal";

export interface ChatMessage {
  character?: StoryCharacter;
  likeCount?: number;
  isLiked?: boolean;
  commentsCount?: number;
  message: React.ReactNode;
  messageStrings?: string[];
  renderAsImageMessage?: boolean;
  imageSrc?: string;
}

export interface Story {
  characters?: StoryCharacter[];
}

async function convertMessageToDatabase(
  chatMessage: ChatMessage,
  id: string,
  index: number
): Promise<any> {
  return new Promise((res, reject) => {
    var dbDic: any = {};
    if (chatMessage.character !== undefined) {
      dbDic.characterID = chatMessage.character?.id;
    }

    dbDic.likeCount = chatMessage.likeCount ?? 0;
    dbDic.commentsCount = chatMessage.commentsCount ?? 0;

    if (chatMessage.renderAsImageMessage && (chatMessage.message as string)) {
      const fileName = index;
      const storageRef = ref(storage, `${id}/${fileName}`);

      uploadString(storageRef, chatMessage.message as string, "data_url")
        .then(() => {
          dbDic.imageName = fileName;
          return res(dbDic);
        })
        .catch(() => {
          return reject();
        });
    } else if (chatMessage.messageStrings !== undefined) {
      dbDic.messageStrings = chatMessage.messageStrings;
      return res(dbDic);
    }
  });
}

const getStorySubtitle = (characters: StoryCharacter[]) => {
  if (!characters || characters?.length < 1) {
    return undefined;
  }

  if (characters?.length === 1) {
    return characters[0].name.split(" ")[0];
  }

  if (characters?.length === 2) {
    return (
      characters[0].name.split(" ")[0] +
      " and " +
      characters[1].name.split(" ")[0]
    );
  }

  if (characters?.length === 3) {
    return (
      characters[0].name.split(" ")[0] +
      ", " +
      characters[1].name.split(" ")[0] +
      ", and " +
      characters[2].name.split(" ")[0]
    );
  }

  return (
    characters[0].name.split(" ")[0] +
    ", " +
    characters[1].name.split(" ")[0] +
    ", " +
    characters[2].name.split(" ")[0] +
    ` and ${characters.length - 3} more`
  );
};

export async function getStoryCharacters(
  characters: { id: string; isReceiver: boolean }[],
  storyId: string
): Promise<StoryCharacter[] | null> {
  if (characters.length < 1) return null;

  const promises: Promise<StoryCharacter>[] = [];

  const ids = characters.map((char) => {
    return char.id;
  });
  const receiver = characters.find((c) => c.isReceiver);

  ids.map((id) => {
    promises.push(getStoryCharacter(id, id === receiver?.id, storyId));
  });

  return Promise.all(promises);
}

async function getStoryCharacter(
  id: string,
  isReceiver: boolean,
  storyId: string
): Promise<StoryCharacter> {
  const docRef = doc(db, "characters", id);

  return new Promise((res, reject) => {
    getDoc(docRef)
      .then((doc) => {
        const data = doc.data();

        const character: StoryCharacter = {
          name: data?.name,
          photo: undefined,
          id: doc.id,
          isReceiver: isReceiver,
          bio: data?.bio,
          birthday: data?.birthday,
          personality: data?.personality,
        };

        const imageRef = ref(
          storage,
          `${storyId}/characterProfilePhotos/${id}`
        );

        getDownloadURL(imageRef)
          .then((url) => {
            character.photo = url;
            console.log("GOT CHAR IMG");
            res(character);
          })
          .catch(() => {
            console.log("FAILED TO GET CHAR IMG");
            res(character);
          });
      })
      .catch(() => {
        reject();
      });
  });
}

const Create = ({ ...props }: Story) => {
  const { characters } = props;
  const [storyCharacters, setCharacters] = React.useState<StoryCharacter[]>(
    characters || []
  );
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const [selectedCharacter, setCharacter] =
    React.useState<StoryCharacter | null>(null);

  const { id } = useParams();
  const [title, setTitle] = React.useState<string | undefined>(undefined);
  const [isPublished, setPublished] = React.useState<boolean>(false);
  const [pageLoading, setPageLoading] = React.useState(true);

  const [views, setViews] = React.useState(0);
  const [likes, setLikes] = React.useState(0);
  const [comments, setComments] = React.useState(0);

  const stats = [
    { title: "Views", number: views },
    { title: "Likes", number: likes },
    { title: "Comments", number: comments },
  ];

  var loadedMessages = false;
  var loadedMain = false;

  // Get messages. Re-render messages when characters are updated.
  React.useEffect(() => {
    console.log("CREATE USE EFFECT LOAD, MESSAGES");

    // Need story ID
    if (!id) {
      window.open("/home", "_self");
    }

    getAuth().onAuthStateChanged((user) => {
      // Need story ID
      if (!id || !user?.uid) {
        window.open("/home", "_self");
      }

      const userRef = doc(db, "userStories", user?.uid!);
      getDoc(userRef)
        .then((snap) => {
          // User is logged in and NOT associated with story, move to home
          if (!snap.data()?.stories?.[id!]) {
            window.open("/home", "_self");
          }

          // User is logged in and is associated with story, let them edit
          loadMessages(id!)
            .then(() => {
              if (!loadedMessages) {
                if (loadedMain) {
                  setPageLoading(false);
                }

                loadedMessages = true;
              }
            })
            .catch(() => {
              if (!loadedMessages) {
                if (loadedMain) {
                  setPageLoading(false);
                }

                loadedMessages = true;
              }
            });
        })
        .catch(() => {
          if (!loadedMessages) {
            if (loadedMain) {
              setPageLoading(false);
            }

            loadedMessages = true;
          }
        });
    });
  }, [storyCharacters]);

  // Main load
  React.useEffect(() => {
    console.log("CREATE USE EFFECT LOAD, MAIN");
    // Need story ID
    if (!id) {
      window.open("/home", "_self");
    }

    getAuth().onAuthStateChanged((user) => {
      // Need story ID
      if (!id || !user?.uid) {
        window.open("/home", "_self");
      }

      logEvent(analytics, "create_page_view");

      const userRef = doc(db, "userStories", user?.uid!);
      getDoc(userRef)
        .then((snap) => {
          // User is logged in and NOT associated with story, move to home
          if (!snap.data()?.stories?.[id!]) {
            window.open("/home", "_self");
          }

          // User is logged in and is associated with story, let them edit
          onSnapshot(doc(db, "stories", id!), async (snapshot) => {
            setTitle(snapshot.data()?.title);
            setPublished(snapshot.data()?.published || false);
            setViews(snapshot.data()?.views || 0);
            setLikes(snapshot.data()?.totalLikes || 0);
            setComments(snapshot.data()?.totalComments || 0);

            const charsToGet: { id: string; isReceiver: boolean }[] = [];
            Object.keys(snapshot.data()?.characters).map((charKey: any) => {
              const char = snapshot.data()?.characters?.[charKey];
              console.log("CHAR: ", char);
              if (char) {
                if (
                  storyCharacters?.find((c) => c.id === charKey) === undefined
                ) {
                  charsToGet.push({
                    id: charKey,
                    isReceiver: char.isReceiver,
                  });
                }
              }
            });

            if (charsToGet.length > 0 && id) {
              getStoryCharacters(charsToGet, id)
                .then((characters) => {
                  if (characters) {
                    characters.sort(
                      (a, b) => Number(b.isReceiver) - Number(a.isReceiver)
                    );
                    setCharacters([...storyCharacters, ...characters]);
                    setCharacter(
                      characters.find((c) => c.isReceiver) || characters[0]
                    );
                  }

                  if (!loadedMain) {
                    if (loadedMessages) {
                      setPageLoading(false);
                    }

                    loadedMain = true;
                  }
                })
                .catch(() => {
                  if (!loadedMain) {
                    if (loadedMessages) {
                      setPageLoading(false);
                    }

                    loadedMain = true;
                  }
                });
            } else {
              if (!loadedMain) {
                if (loadedMessages) {
                  setPageLoading(false);
                }

                loadedMain = true;
              }
            }
          });
        })
        .catch(() => {
          if (!loadedMain) {
            if (loadedMessages) {
              setPageLoading(false);
            }

            loadedMain = true;
          }
        });
    });
  }, []);

  // Refs
  var bottomMessageRef = React.createRef<HTMLDivElement>();

  async function loadMessages(storyID: string): Promise<void> {
    getChatMessageArrayFromFirebase(storyID)
      .then((messageChats) => {
        setMessages(messageChats);
        Promise.resolve();
      })
      .catch(() => {
        Promise.reject();
      });
  }

  const getEditorMessageRow = (
    message: ChatMessage,
    idx: number,
    forMessages?: ChatMessage[]
  ) => {
    var tempMessages = messages;
    if (forMessages != undefined) {
      tempMessages = forMessages!;
    }

    const showPhoto =
      message.character &&
      !(
        messages.length > idx + 1 &&
        message.character.id === tempMessages[idx + 1].character?.id
      );
    const showName =
      message.character &&
      !(
        idx > 0 && message.character.id === tempMessages[idx - 1].character?.id
      );

    if (message.imageSrc) {
      console.log("IMAGE: ", message.imageSrc);
    }

    return (
      <MessageRow
        key={`message-${idx}`}
        character={message.character}
        showPhoto={showPhoto}
        showName={showName}
        renderAsImageMessage={message.renderAsImageMessage}
        imageSrc={message.imageSrc}
        commentsCount={message.commentsCount}
        index={idx}
      >
        {message.message}
      </MessageRow>
    );
  };

  const deleteLastMessage = async () => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      setMessages(messages.slice(0, messages.length - 1));

      console.log("updateMessages: storyID " + id);
      const storyRef = doc(db, "storyMessages", `${id!}`);

      // Get current length of messages array from db
      var messagesLength = 0;
      await getDoc(storyRef).then((snap) => {
        if (snap.data()) {
          messagesLength = Object.keys(snap.data()!).length;
        }
      });

      await setDoc(
        storyRef,
        { [messagesLength]: deleteField() },
        { merge: true }
      ).then(function () {
        console.log("updateMessages: Document successfully deleted! ");
        // Update count of messages from this character in the story
        if (lastMessage.character?.id && id) {
          const charRef = doc(db, "stories", id);
          setDoc(
            charRef,
            {
              characters: {
                [lastMessage.character.id]: { messageCount: increment(-1) },
              },
            },
            { merge: true }
          );
        }
      });
    }
  };

  const updateMessages = async (message: ChatMessage) => {
    const combinedMessages = [...messages, message];
    setMessages(combinedMessages);

    console.log("combinedMessages");
    console.log(combinedMessages);

    console.log("updateMessages: storyID " + id);

    if (id) {
      console.log("IN ID");
      const storyRef = doc(db, "storyMessages", id);

      // Get current length of messages array from db
      var messagesLength = 0;
      getDoc(storyRef)
        .then((snap) => {
          if (snap.data()) {
            messagesLength = Object.keys(snap.data() || {}).length;
          }

          convertMessageToDatabase(message, id, messagesLength + 1).then(
            (messageDic) => {
              console.log("messageDic");
              console.log(messageDic);

              setDoc(
                storyRef,
                { [messagesLength + 1]: messageDic },
                { merge: true }
              )
                .then(() => {
                  console.log(
                    "updateMessages: Document successfully written! "
                  );
                  // Update count of messages from this character in the story
                  if (message.character?.id && id) {
                    const charRef = doc(db, "stories", id);
                    setDoc(
                      charRef,
                      {
                        characters: {
                          [message.character.id]: {
                            messageCount: increment(1),
                          },
                        },
                      },
                      { merge: true }
                    );
                  }
                })
                .catch((err) => {
                  console.log("FAILED TO WRITE: ", err);
                });
            }
          );
        })
        .catch((err) => {
          console.log("COULD NOT GET DOC: ", err);
        });
    }
  };

  const getInputSection = () => {
    console.log("selectedCharacter ", selectedCharacter);
    return (
      <ChatInputField
        showLoadingState={pageLoading}
        selected={selectedCharacter?.id}
        characters={storyCharacters}
        disableSend={(storyCharacters?.length || 0) < 1}
        sentMessage={(message, messageStrings) => {
          console.log(messageStrings);
          selectedCharacter &&
            updateMessages({
              message: message,
              character: selectedCharacter,
              messageStrings: messageStrings,
            });
        }}
        addedNarrativeText={(text) => {
          updateMessages({
            message: <>{text}</>,
            messageStrings: [text],
          });
        }}
        reloadMessage={(message, sentCharacter) => {
          updateMessages({
            message: message,
            character: sentCharacter,
          });
        }}
        sentImage={(imageSrc) => {
          selectedCharacter &&
            updateMessages({
              message: imageSrc,
              renderAsImageMessage: true,
              character: selectedCharacter,
              messageStrings: [imageSrc],
            });
        }}
        selectedCharacter={(character) => {
          setCharacter(character);
        }}
        didUndo={() => deleteLastMessage()}
      />
    );
  };

  // Happens when messages are sent
  React.useEffect(() => {
    if (bottomMessageRef.current) {
      bottomMessageRef.current.scrollIntoView({
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });
    }
  }, [messages]);

  const Header = () => (
    <ChatHeader
      id={id}
      title={title}
      subtitle={getStorySubtitle(storyCharacters)}
      readerView={false}
      isPublished={isPublished}
      enablePublish={messages.length > 0}
      characters={storyCharacters}
      showLoadingState={pageLoading}
      storyStats={stats}
    />
  );

  return (
    <Page
      pageTitle="Create | Tiny Fiction"
      showNav={false}
      secondaryCol={
        isDesktop && (
          <Box style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Box
              style={{
                background: "rgba(0,0,0,0.025)",
                borderRadius: 12,
                overflow: "hidden",
                border: "0.5px solid lightgray",
              }}
            >
              <Box
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderBottom: "1px solid lightgray",
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 600 }}>
                  Story stats
                </Text>
              </Box>

              <Box style={{ padding: 16 }}>
                <Flex>
                  <StatsInner storyStats={stats} isPublished={isPublished} />
                </Flex>

                {!isPublished && (
                  <Text variant="meta" style={{ marginTop: 16 }}>
                    After you publish your story, stats will update here
                  </Text>
                )}
              </Box>
            </Box>
            <Box
              style={{
                background: "rgba(0,0,0,0.025)",
                borderRadius: 12,
                overflow: "hidden",
                marginTop: 16,
                border: "0.5px solid lightgray",
              }}
            >
              <Box
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  paddingTop: 12,
                  paddingBottom: 12,
                  borderBottom: "1px solid lightgray",
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 600 }}>
                  Characters
                </Text>
              </Box>

              {(storyCharacters?.length || 0) > 0 ? (
                <>
                  {storyCharacters?.map((character, idx) => (
                    <CharacterListItem
                      character={character}
                      interactive={false}
                      noBackground
                      noSeparator={idx === storyCharacters?.length - 1}
                      key={character.id}
                    />
                  ))}
                </>
              ) : (
                <Box style={{ padding: 16 }}>
                  <Text variant="meta">
                    {pageLoading
                      ? "Loading..."
                      : "Add characters to your story"}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        )
      }
      stickyHeader={!isDesktop ? <Header /> : undefined}
      stickyFooter={!isDesktop ? getInputSection() : undefined}
    >
      <Flex
        style={{
          height: isDesktop ? "100vh" : "100%",
          flexDirection: "column",
          flexGrow: 1,
          borderLeft: isDesktop ? "1px solid lightgray" : undefined,
          borderRight: isDesktop ? "1px solid lightgray" : undefined,
        }}
      >
        {isDesktop && <Header />}
        <Container
          style={{
            paddingLeft: !isDesktop ? 0 : undefined,
            paddingRight: !isDesktop ? 0 : undefined,
            paddingTop: 14,
            overflowY: "scroll",
            flex: 1,
          }}
        >
          {messages.length === 0 && (
            <Text
              variant="meta"
              style={{
                textAlign: "center",
                fontSize: 14,
                paddingLeft: 30,
                paddingRight: 30,
                fontWeight: 500,
              }}
            >
              Add characters and start sending messages to create your story!
            </Text>
          )}
          {messages.length > 0 &&
            messages.map((message, idx) => getEditorMessageRow(message, idx))}
          <Box
            style={{
              float: "left",
              clear: "both",
            }}
            ref={bottomMessageRef}
          />
        </Container>
        {isDesktop && getInputSection()}
      </Flex>
    </Page>
  );
};

export default Create;
