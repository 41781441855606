import Page from "../components/Page";
import React from "react";
import Box from "../components/patterns/Box";
import Flex from "../components/patterns/Flex";
import UserPhoto from "../components/patterns/UserPhoto";
import ButtonPill from "../components/patterns/ButtonPill";
import Text from "../components/patterns/Text";
import {
  ChevronRightIcon,
  ShareIcon,
  SortDescIcon,
} from "@primer/octicons-react";
import { doc } from "firebase/firestore";
import { db, storage } from "../utils/firebase";
import { getChatMessageArrayFromFirebase } from "./Read";
import { ChatMessage, getStoryCharacters } from "./Create";
import { getDoc } from "firebase/firestore";
import {
  getUserPhotoPlaceholderString,
  MessageRow,
} from "../components/messages/MessageRow";
import { isDesktop, isMobile } from "react-device-detect";
import { StoryCharacter } from "../components/messages/ChatInputField";
import { getChatSubtitle } from "../components/ChatHeader";
import { useParams } from "react-router-dom";
import DropButton from "../components/patterns/DropButton";
import { getAuth } from "firebase/auth";
import { IoChevronForward, IoSettingsOutline } from "react-icons/io5";
import noStories from "../../src/assets/noStories.png";
import CreateStoryButton from "../components/story/CreateStoryButton";
import { getDownloadURL, ref, uploadString } from "firebase/storage";

enum SortOption {
  recency = "Most recent",
  mostViewed = "Most viewed",
  unpublished = "Unpublished",
}

export function convertChatMessageToUI(message: ChatMessage): any {
  return (
    <MessageRow
      key={`message-}`}
      character={message.character}
      showPhoto={true}
      showName={true}
      renderAsImageMessage={message.renderAsImageMessage}
    >
      {message.message}
    </MessageRow>
  );
}

export const StoryCard = ({
  marginTop = true,
  storyId,
  viewerIsAuthor = false,
  index = 0,
}: {
  marginTop?: boolean;
  index: number;
  viewerIsAuthor?: boolean;
  storyId: string;
}) => {
  const [title, setTitle] = React.useState<string>();
  const [views, setViews] = React.useState<number>(0);
  const [isPublished, setPublished] = React.useState<boolean>(false);
  const [storyCharacters, setCharacters] = React.useState<StoryCharacter[]>([]);
  const [featuredMessages, setFeaturedMessages] = React.useState<ChatMessage[]>(
    []
  );

  React.useEffect(() => {
    // Story data (title, characters)
    getDoc(doc(db, "stories", storyId))
      .then((snapshot) => {
        console.log("SNAP: ", snapshot.data());
        setTitle(snapshot.data()?.title);
        setViews(snapshot.data()?.views || 0);
        setPublished(snapshot.data()?.published || false);

        if (viewerIsAuthor || snapshot.data()?.published || false) {
          // Message data
          getChatMessageArrayFromFirebase(storyId).then((messages) => {
            setFeaturedMessages(messages);
          });

          console.log("SNAPSHOT");

          const charsToGet: { id: string; isReceiver: boolean }[] = [];
          console.log(
            "STORY CARD OBJECT KEYS: ",
            Object.keys(snapshot.data()?.characters)
          );
          Object.keys(snapshot.data()?.characters).map((charKey: any) => {
            const char = snapshot.data()?.characters?.[charKey];
            if (storyCharacters?.find((c) => c.id === charKey) === undefined) {
              charsToGet.push({ id: charKey, isReceiver: char.isReceiver });
            }
          });

          console.log("STORY CARD CHARS TO GET: ", charsToGet);
          if (charsToGet.length > 0) {
            getStoryCharacters(charsToGet, storyId).then((characters) => {
              if (characters) {
                console.log("STORY CARD RESULT: ", characters);
                setCharacters([...storyCharacters, ...characters]);
              }
            });
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  if (!viewerIsAuthor && !isPublished) {
    return null;
  }

  return (
    <Box
      onClick={() => {
        if (viewerIsAuthor) {
          window.open(`/create/${storyId}`);
        } else {
          window.open(`/read/${storyId}`);
        }
      }}
      style={{
        background: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
        borderRadius: 12,
        border: "1px solid lightgray",
        overflow: "hidden",
        marginTop: marginTop ? 12 : undefined,
        cursor: "pointer",
      }}
    >
      <Flex
        style={{
          background: "rgba(0,0,0,0.03)",
          borderBottom: "1px solid rgba(0,0,0,0.05)",
          alignItems: "center",
          padding: 10,
        }}
      >
        <Flex style={{ flexDirection: "column", flex: 1, paddingRight: 10 }}>
          <Text>{title}</Text>
          <Text variant="meta" style={{ marginTop: 4 }}>
            {getChatSubtitle(storyCharacters)}
          </Text>
        </Flex>
        <ChevronRightIcon />
      </Flex>
      <Box style={{ position: "relative" }}>
        <Box style={{ height: 165, overflow: "hidden", padding: 10 }}>
          {featuredMessages.length > 0 &&
            featuredMessages.map((message, idx) => (
              <MessageRow
                renderReaderView
                key={`message-${idx}`}
                hideButtons
                character={message.character}
                showPhoto={
                  message.character &&
                  !(
                    featuredMessages.length > idx + 1 &&
                    message.character.id ===
                      featuredMessages[idx + 1].character?.id
                  )
                }
                showName={
                  idx === 0 ||
                  (idx - 1 >= 0 &&
                    message?.character?.id !==
                      featuredMessages[idx - 1]?.character?.id)
                }
                renderAsImageMessage={message.renderAsImageMessage}
                didLikeMessage={() => {
                  message.isLiked = true;
                }}
              >
                {message.message}
              </MessageRow>
            ))}
        </Box>
        <Box
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            height: 100,
            width: "100%",
            background: "linear-gradient(transparent,white)",
          }}
        />
      </Box>
      <Flex
        style={{
          background: "rgba(0,0,0,0.03)",
          borderTop: "1px solid rgba(0,0,0,0.05)",
          alignItems: "center",
          justifyContent: viewerIsAuthor ? "space-between" : undefined,
          padding: 10,
        }}
      >
        <Text variant="meta">
          {views} {views === 1 ? "view" : "views"} ·{" "}
          {featuredMessages.length || 0}{" "}
          {featuredMessages.length === 1 ? "message" : "messages"}
        </Text>
        {viewerIsAuthor && (
          <Box
            style={{
              padding: 4,
              borderRadius: 6,
              background: isPublished ? "#73BA9B" : "gray",
              width: "min-content",
              marginLeft: 8,
            }}
          >
            <Text
              style={{
                textTransform: "uppercase",
                fontSize: 11,
                color: "white",
                fontWeight: "600",
              }}
            >
              {isPublished ? "Published" : "Draft"}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

const Profile = () => {
  const [realName, setRealName] = React.useState("");
  const [disName, setDisName] = React.useState("");
  const [bio, setBio] = React.useState<string | undefined>(undefined);

  const [imgURL, setImgURL] = React.useState<string | undefined>(undefined);
  const [userStoryViews, setUserStoryViews] = React.useState(0);
  const [totalStories, setTotalStories] = React.useState(0);
  const [userLikes, setUserLikes] = React.useState(0);
  const [viewerIsAuthor, setViewerIsAuthor] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);

  const [storyIDs, setStoryIDs] = React.useState<string[]>([]);
  const { id } = useParams();

  var loadedProfileInfo = false;

  const loadProfile = (user: string) => {
    const profileRef = doc(db, "userProfiles", `${user}`);
    getDoc(profileRef).then((snap) => {
      if (snap.data()) {
        const imgURL = snap.data()?.photo;
        const userTotalViews = snap.data()?.totalViews;
        const realName = snap.data()?.name;
        const displayName = snap.data()?.displayName;
        const userLikes = snap.data()?.totalLove;

        setRealName(realName);
        setDisName(displayName || realName);
        setImgURL(imgURL);
        setUserStoryViews(userTotalViews);
        setUserLikes(userLikes);
        setBio(snap.data()?.bio);

        const profilePhotoRef = ref(storage, `profilePhotos/${id}`);
        getDownloadURL(profilePhotoRef).then((url) => {
          setImgURL(url);
        });

        if (!loadedProfileInfo) {
          setPageLoading(false);
          loadedProfileInfo = true;
        }
      }
    });
  };

  React.useEffect(() => {
    if (id) {
      getAuth().onAuthStateChanged((user) => {
        const loggedInUserId = user?.uid;
        setViewerIsAuthor(loggedInUserId === id);

        // Get main profile info
        loadProfile(id);

        // Load stories
        const storiesRef = doc(db, `userStories`, `${id}`);
        getDoc(storiesRef).then((snap) => {
          const stories = snap?.data()?.stories;
          if (stories && Object.keys(stories).length > 0) {
            var idsToLoad: any[] = [];
            var items = Object.keys(stories).map((key) => {
              return [key, stories[key]];
            });

            items.sort((first, second) => {
              return second[0].localeCompare(first[0]);
            });

            items.map((e) => {
              if (e[1].published || false || loggedInUserId === id) {
                idsToLoad.push(e[0]);
              }
            });

            setTotalStories(idsToLoad.length);
            setStoryIDs(idsToLoad);
          }
        });
      });
    } else {
      window.open("/home", "_self");
    }
  }, []);

  const dropOptions = [
    {
      text: "Update profile photo",
      id: "profilePhoto",
    },
    {
      text: "Change display name",
      id: "displayName",
    },
  ];

  const ProfileHeader = () => {
    const { id } = useParams();

    var imgUploadRef = React.createRef<HTMLInputElement>();

    const imageReader = new FileReader();
    imageReader.onload = (e) => {
      if (e.target?.result && typeof e.target?.result === "string") {
        console.log(e.target.result);

        // Upload to storage
        const storageRef = ref(storage, `profilePhotos/${id}`);

        uploadString(storageRef, e.target.result, "data_url")
          .then(() => {
            getDownloadURL(storageRef).then((url) => {
              setImgURL(url);
            });

            // TODO: Add a success message
          })
          .catch(() => {
            // TODO: Add an error message
          });

        setImgURL(e.target.result);
      }
    };

    const handleImageSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
      const chosenFile = e.target?.files?.[0];
      if (chosenFile) {
        imageReader.readAsDataURL(chosenFile);
      }
    };

    return (
      <Box
        style={{
          paddingTop: isMobile ? 24 : 12,
          paddingBottom: 24,
          borderBottom: "1px solid lightgray",
        }}
      >
        <Flex>
          {isMobile && <Flex style={{ flex: 1 }} />}
          <Flex
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: isMobile ? "center" : undefined,
            }}
          >
            <UserPhoto
              size={isDesktop ? 120 : 80}
              URL={imgURL}
              placeholderText={
                realName ? getUserPhotoPlaceholderString(realName) : undefined
              }
            />
          </Flex>
          <Flex
            style={{
              flex: 1,
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            {viewerIsAuthor ? (
              <>
                <DropButton
                  onSelect={async (option) => {
                    if (option === "profilePhoto") {
                      if (imgUploadRef.current) {
                        imgUploadRef.current.click();
                      }
                    }

                    if (option === "displayName") {
                    }
                  }}
                  variant="outline-secondary"
                  size="sm"
                  items={dropOptions}
                  title={
                    <Flex
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flex: 1,
                        height: 20,
                        paddingRight: 14,
                        fontWeight: 500,
                      }}
                    >
                      <IoSettingsOutline
                        size={18}
                        style={isDesktop ? { marginRight: 4 } : undefined}
                      />
                    </Flex>
                  }
                />
                <input
                  ref={imgUploadRef}
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageSelection(e)}
                />
              </>
            ) : (
              <ButtonPill
                size="sm"
                variant="outline-secondary"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  height: 32,
                  width: 32,
                }}
                onClick={() => {}}
              >
                {pageLoading ? (
                  <Flex
                    style={{
                      borderRadius: 100,
                      width: 16,
                      height: 16,
                      background: "rgba(0,0,0,0.1)",
                    }}
                  />
                ) : (
                  <ShareIcon size={14} />
                )}
              </ButtonPill>
            )}
          </Flex>
        </Flex>
        <Flex
          style={{
            flex: 1,
            alignItems: isMobile ? "center" : undefined,
            flexDirection: "column",
          }}
        >
          <Text
            style={{
              textAlign: isMobile ? "center" : undefined,
              fontWeight: 600,
              marginTop: 10,
              background: pageLoading ? "rgba(0,0,0,0.1)" : undefined,
              borderRadius: pageLoading ? 100 : undefined,
              color: pageLoading ? "transparent" : undefined,
              width: pageLoading ? "fit-content" : undefined,
              fontSize: isDesktop ? 18 : undefined,
            }}
          >
            {pageLoading ? <>Profile name</> : disName}
          </Text>
          <Text
            variant="meta"
            style={{
              textAlign: isMobile ? "center" : undefined,
              marginTop: 8,
              fontSize: isDesktop ? 14 : undefined,
            }}
          >
            {userStoryViews} story {userStoryViews === 1 ? "view" : "views"} ·{" "}
            {userLikes} {userLikes === 1 ? "like" : "likes"}
          </Text>
          {bio && (
            <Text
              style={{
                textAlign: isMobile ? "center" : undefined,
                marginTop: 8,
              }}
            >
              {bio}
            </Text>
          )}
        </Flex>
      </Box>
    );
  };

  const [selected, setSort] = React.useState(SortOption.recency);
  const sortOptions: SortOption[] = [
    SortOption.recency,
    SortOption.mostViewed,
    SortOption.unpublished,
  ];

  return (
    <Page pageTitle="Profile | Tiny Fiction">
      <Box style={{ paddingBottom: 12 }}>
        <ProfileHeader />
        <Flex
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: 14,
            paddingBottom: 14,
          }}
        >
          <Text style={{ fontSize: 14, fontWeight: 600 }}>
            {totalStories} {totalStories === 1 ? "story" : "stories"}
          </Text>
          <DropButton
            disabled={totalStories <= 1}
            onSelect={(s) => {}}
            size="sm"
            variant="outline-secondary"
            style={{ minWidth: 0 }}
            title={
              <Box
                style={{
                  alignItems: "center",
                  marginRight: 16,
                  fontWeight: 500,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <SortDescIcon size={14} />{" "}
                {sortOptions.find((s) => s === selected)}
              </Box>
            }
            items={sortOptions.map((s) => {
              return {
                text: s,
                id: s,
                active: selected === s || false,
              };
            })}
          />
        </Flex>
        {storyIDs.map((id, idx) => (
          <StoryCard
            marginTop={idx > 0}
            index={0}
            storyId={id}
            viewerIsAuthor={viewerIsAuthor}
          />
        ))}
        {totalStories === 0 && (
          <Flex
            style={{
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img src={noStories} style={{ height: 86 }} />
            <Text style={{ marginTop: 8, color: "gray", fontSize: 13 }}>
              <>
                {viewerIsAuthor
                  ? "You haven't"
                  : `${disName?.split(" ")?.[0] || disName} hasn't`}{" "}
                created any stories yet
              </>
            </Text>
            {viewerIsAuthor && (
              <CreateStoryButton style={{ marginTop: 14 }}>
                <Flex style={{ alignItems: "center" }}>
                  Write your first story{" "}
                  <IoChevronForward size={16} style={{ marginLeft: 4 }} />
                </Flex>
              </CreateStoryButton>
            )}
          </Flex>
        )}
      </Box>
    </Page>
  );
};

export default Profile;
