import { isDesktop } from "react-device-detect";
import { IoClose } from "react-icons/io5";
import Box from "./Box";
import Flex from "./Flex";
import Text from "./Text";

interface BannerProps {
  dismissible?: boolean;
  title: string;
  variant?: "blue" | "gray";
  subtitle?: JSX.Element | string;
  image?: JSX.Element;
  onClick?: VoidFunction;
  attachmentElement?: JSX.Element;
}

const Banner = ({
  dismissible = false,
  title,
  variant = "blue",
  subtitle,
  image,
  onClick,
  attachmentElement,
}: BannerProps) => {
  const bgColor =
    variant === "blue" ? "rgba(13, 110, 253, 0.05)" : "rgba(0, 0, 0, 0.01)";

  return (
    <Flex
      style={{
        borderRadius: 10,
        background: bgColor,
        padding: 12,
        marginBottom: 16,
        border: "0.5px solid rgb(0,0,0,0.1)",
        position: "relative",
        flexDirection: "column",
      }}
    >
      <Flex>
        {image && (
          <Flex
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginRight: 10,
            }}
          >
            {image}
          </Flex>
        )}
        <Flex
          style={{ flexDirection: "column", justifyContent: "center", flex: 1 }}
        >
          <Text style={{ fontWeight: "bold" }}>{title}</Text>
          {subtitle && (
            <Text
              style={{
                marginTop: 6,
                fontSize: isDesktop ? 15 : 13,
                color: "gray",
              }}
            >
              {subtitle}
            </Text>
          )}
        </Flex>
      </Flex>
      {attachmentElement && <Flex>{attachmentElement}</Flex>}
      {dismissible && (
        <Flex
          onClick={onClick}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          <IoClose size={18} color="gray" />
        </Flex>
      )}
    </Flex>
  );
};

export default Banner;
