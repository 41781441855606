import { logEvent } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { ButtonProps } from "react-bootstrap";
import { analytics, db } from "../../utils/firebase";
import ModalOpener from "../modal/manager/ModalOpener";
import { ModalRegistry } from "../modal/utils";
import { SiteTheme, ThemeContext } from "../Page";
import ButtonPill from "../patterns/ButtonPill";
import { generatePlaceholderTitle } from "../SiteNav";
import React from "react";

interface CreateStoryButtonProps extends ButtonProps {}
export const purpleButtonStyle = {
  background: "#CF7A76",
  color: "white",
  borderColor: "#CF7A76",
};

const CreateStoryButton = ({
  children,
  style,
  ...buttonProps
}: CreateStoryButtonProps) => {
  const theme = React.useContext(ThemeContext);
  const combinedStyle = {
    ...style,
    ...(theme === SiteTheme.purple ? purpleButtonStyle : {}),
  };

  return (
    <ModalOpener modal={ModalRegistry.signupModal}>
      {(options) => (
        <ButtonPill
          size="sm"
          {...buttonProps}
          onClick={() => {
            logEvent(analytics, "create_story_attempt");

            var auth = getAuth();
            var user = auth.currentUser;
            var isLoggedIn = user !== null;

            if (isLoggedIn) {
              addDoc(collection(db, "stories"), {
                title: generatePlaceholderTitle(),
                characters: [],
                views: 1,
                totalLikes: 0,
                totalComments: 0,
                author: user?.uid,
              }).then((docRef) => {
                const uid = auth.currentUser?.uid;
                if (uid) {
                  const secondsSinceEpoch = `${Math.round(Date.now() / 1000)}`;
                  const userRef = doc(db, "userStories", uid);
                  setDoc(
                    userRef,
                    {
                      stories: {
                        [docRef.id]: {
                          timeCreated: secondsSinceEpoch,
                        },
                      },
                    },
                    { merge: true }
                  ).then(() => {
                    console.log("Added story to user profile");
                  });
                  window.open(`/create/${docRef.id}`, "_self");
                }
              });
            } else {
              console.log("OPEN SIGN");
              options.toggleModal(true);
            }
          }}
          style={combinedStyle}
        >
          {children || <>Create story</>}
        </ButtonPill>
      )}
    </ModalOpener>
  );
};

export default CreateStoryButton;
