import { Navbar, Container, Nav } from "react-bootstrap";
import ButtonPill from "./patterns/ButtonPill";
import Flex from "./patterns/Flex";
import ModalOpener from "./modal/manager/ModalOpener";
import { getAuth, updateProfile } from "firebase/auth";
import { ModalRegistry } from "./modal/utils";
import UserPhoto from "./patterns/UserPhoto";
import logo from "../../src/assets/logo.svg";
import whiteLogo from "../../src/assets/whiteLogo.svg";
import { getUserPhotoPlaceholderString } from "./messages/MessageRow";
import CreateStoryButton from "./story/CreateStoryButton";
import { SiteTheme, ThemeContext } from "./Page";
import React from "react";
import { storage } from "../utils/firebase";
import { getDownloadURL, ref } from "firebase/storage";
import { useParams } from "react-router-dom";

export const SITE_NAV_HEIGHT = 60;

export const generatePlaceholderTitle = () => {
  const adjectives = {
    amazing: { articles: ["An", "The"] },
    best: { articles: ["The"] },
    historic: { articles: ["A", "The"] },
  };
  const last: string[] = ["group", "journey", "story"];

  const lastOne = Math.floor(Math.random() * last.length);
  const adj = Object.keys(adjectives)[
    Math.floor(Math.random() * Object.keys(adjectives).length)
  ] as keyof typeof adjectives;
  return (
    adjectives[adj].articles[
      Math.floor(Math.random() * adjectives[adj].articles.length)
    ] +
    " " +
    adj +
    " " +
    last[lastOne]
  );
};

const SiteNav = () => {
  var auth = getAuth();
  var user = auth.currentUser;

  var isLoggedIn = user !== null;
  console.log("user ", user);
  console.log("isLoggedIn ", isLoggedIn);

  const theme = React.useContext(ThemeContext);

  const [imgURL, setImgURL] = React.useState<string | undefined>(undefined);
  if (auth.currentUser?.uid) {
    const profilePhotoRef = ref(
      storage,
      `profilePhotos/${auth.currentUser?.uid}`
    );
    getDownloadURL(profilePhotoRef).then((url) => {
      setImgURL(url);
    });
  }

  return (
    <Navbar
      expand="lg"
      style={{
        height: `${SITE_NAV_HEIGHT}px`,
        flex: 1,
        background: theme === SiteTheme.regular ? "#f9f9f9" : "transparent",
        borderBottom: `1px solid ${
          theme === SiteTheme.regular ? "lightgray" : "rgba(255,255,255,0.2)"
        }`,
      }}
    >
      <Container style={{ maxWidth: 1200 }}>
        <Navbar.Brand href="/" style={{ fontWeight: 700, fontSize: 18 }}>
          <img
            src={theme === SiteTheme.regular ? logo : whiteLogo}
            style={{ height: 30, marginRight: 6 }}
          />
        </Navbar.Brand>
        <Nav>
          <Flex style={{ alignItems: "center" }}>
            {!isLoggedIn ? (
              <ModalOpener
                modal={ModalRegistry.signupModal}
                modalProps={{ signUp: true }}
              >
                {(options) => (
                  <ButtonPill
                    size="sm"
                    variant="outline-secondary"
                    style={{ marginRight: 6 }}
                    onClick={() => options.toggleModal(true)}
                  >
                    Sign up
                  </ButtonPill>
                )}
              </ModalOpener>
            ) : (
              <UserPhoto
                size={32}
                URL={imgURL}
                userId={auth.currentUser?.uid}
                placeholderText={
                  auth.currentUser?.displayName
                    ? getUserPhotoPlaceholderString(
                        auth.currentUser?.displayName
                      )
                    : undefined
                }
                style={{ marginRight: 6 }}
              />
            )}
            <CreateStoryButton />
          </Flex>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default SiteNav;
