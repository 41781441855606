import React from "react";

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {}

const Box = React.forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { children, ...restProps } = props;
  return (
    <div {...restProps} ref={ref as React.RefObject<HTMLDivElement>}>
      {children}
    </div>
  );
});

export default Box;
