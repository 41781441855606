import React from "react";
import { useParams } from "react-router-dom";
import Modal, { ModalProps } from "../modal/Modal";
import Box from "../patterns/Box";
import Flex from "../patterns/Flex";
import Text from "../patterns/Text";
import pencilWrite from "../../../src/assets/pencilWrite.png";
import { Form } from "react-bootstrap";
import ButtonPill from "../patterns/ButtonPill";
import { PaperAirplaneIcon } from "@primer/octicons-react";
import UserPhoto from "../patterns/UserPhoto";
import { getUserPhotoPlaceholderString } from "../messages/MessageRow";
import { getAuth, User } from "firebase/auth";
import { ModalRegistry } from "../modal/utils";
import ModalOpener from "../modal/manager/ModalOpener";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  increment,
  setDoc,
} from "firebase/firestore";
import { analytics, db, storage } from "../../utils/firebase";
import StoryCommentItem from "./StoryCommentItem";
import { logEvent } from "firebase/analytics";
import { isDesktop } from "react-device-detect";
import { getDownloadURL, ref } from "firebase/storage";

const COMMENT_CHAR_LIMIT = 240;

export interface StoryComment {
  id?: string;
  time?: number;
  authorId?: string;
  authorProfilePhoto?: string;
  name?: string;
  likes?: number;
  commentText?: string;
  isLiked?: boolean;
}

export interface MessageDetailsModalProps extends ModalProps {
  message?: JSX.Element;
  messageIndex?: number;
  didSubmitComment?: (comment: string) => void;
  renderReaderView?: boolean;
}

async function getCommentData(id: string): Promise<StoryComment> {
  const commentRef = doc(db, "storyComments", id);
  const userId = getAuth().currentUser?.uid;

  return new Promise((res, reject) => {
    getDoc(commentRef)
      .then(async (comData) => {
        const author = comData?.data()?.author;

        if (author) {
          const userRef = doc(db, "userProfiles", author);

          var loggedInUserLiked = false;
          if (userId && comData.data()?.likerIds?.includes?.(userId)) {
            loggedInUserLiked = true;
          }

          getDoc(userRef)
            .then(async (authorInfo) => {
              const newComment: StoryComment = {
                id: id,
                time: comData.data()?.time,
                authorId: author,
                name: authorInfo.data()?.displayName,
                likes: comData.data()?.likes || 0,
                commentText: comData.data()?.text,
                isLiked: loggedInUserLiked,
              };

              if (author) {
                const profilePhotoRef = ref(storage, `profilePhotos/${author}`);
                getDownloadURL(profilePhotoRef)
                  .then((url) => {
                    newComment.authorProfilePhoto = url;
                    return res(newComment);
                  })
                  .catch(() => {
                    return res(newComment);
                  });
              }
            })
            .catch(() => {
              return reject();
            });
        } else {
          return reject();
        }
      })
      .catch(() => {
        return reject();
      });
  });
}

export async function getMessageComments(
  storyId: string,
  messageIndex: number
): Promise<StoryComment[]> {
  const promises: Promise<StoryComment>[] = [];

  const storyRef = doc(db, "storyMessages", storyId);
  await getDoc(storyRef).then((story) => {
    const storyData = story.data();
    const commentIds = storyData?.[messageIndex + 1].commentIds;

    if (commentIds) {
      commentIds.map(async (commentId: string) => {
        promises.push(getCommentData(commentId));
      });
    }
  });

  return Promise.all(promises);
}

const MessageDetailsModal = ({
  message,
  messageIndex,
  isVisible,
  didSubmitComment,
  renderReaderView = false,
  ...restProps
}: MessageDetailsModalProps) => {
  const { id } = useParams();
  const [input, setInput] = React.useState("");
  const [commentList, setCommentList] = React.useState<StoryComment[]>([]);
  const [user, setUser] = React.useState<User | null>(null);
  const [pageLoading, setPageLoading] = React.useState(true);

  var gotComments = false;
  React.useEffect(() => {
    console.log("USE EFFECT");
    const loggedIn = getAuth().currentUser;
    if (loggedIn) {
      setUser(loggedIn);
    }

    if (!gotComments && id && messageIndex !== undefined) {
      getMessageComments(id, messageIndex)
        .then((c) => {
          console.log("RETURN: ", c);
          console.log("GOT COMMENTS: ", c.length);
          setCommentList(c);
          gotComments = true;

          setTimeout(() => {
            setPageLoading(false);
          }, 500);
        })
        .catch(() => {
          setPageLoading(false);
        });
    } else {
      setPageLoading(false);
    }
  }, []);

  return (
    <Modal
      title="Comments"
      footer={
        renderReaderView ? (
          <Flex
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: 12,
              paddingBottom: 12,
              background: "white",
              borderTop: "1px solid lightgray",
              boxShadow: "0 -2px 4px rgba(0,0,0,0.05)",
            }}
          >
            {user && (
              <UserPhoto
                size={38}
                placeholderText={
                  user.displayName
                    ? getUserPhotoPlaceholderString(user.displayName)
                    : undefined
                }
                style={{ marginRight: 8 }}
              />
            )}
            <Form.Control
              maxLength={COMMENT_CHAR_LIMIT}
              placeholder="Write a comment..."
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />
            <ModalOpener
              modal={ModalRegistry.signupModal}
              modalProps={{
                subtitle: "Create a free account to join the conversation",
              }}
            >
              {(options) => (
                <ButtonPill
                  variant="primary"
                  style={{ marginLeft: 8 }}
                  disabled={!input || (input?.trim().length || 0) === 0}
                  onClick={() => {
                    if (input && (input?.trim().length || 0) > 0) {
                      if (!user) {
                        // User not logged in, show signup wall
                        logEvent(analytics, "story_comment_attempt");

                        options.toggleModal(true);
                      } else if (id) {
                        setCommentList([
                          {
                            commentText: input,
                            authorId: user.uid,
                            likes: 0,
                            time: Date.now(),
                          },
                          ...commentList,
                        ]);

                        didSubmitComment?.(input);
                      }
                    }
                  }}
                >
                  <Flex style={{ alignItems: "center" }}>
                    <PaperAirplaneIcon size={16} />
                  </Flex>
                </ButtonPill>
              )}
            </ModalOpener>
          </Flex>
        ) : undefined
      }
      maxWidth={isDesktop ? 600 : undefined}
      height={isDesktop ? "80vh" : undefined}
      {...restProps}
    >
      <Box style={{ width: "100%" }}>
        {message && <Flex>{message}</Flex>}
        <Flex
          style={{
            paddingBottom: 4,
            marginTop: 24,
            borderBottom: "1px solid lightgray",
            marginLeft: -16,
            marginRight: -16,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <Text variant="meta">
            {commentList?.length || 0}{" "}
            {(commentList?.length || 0) === 1 ? "comment" : "comments"}
          </Text>
        </Flex>
        {commentList?.length === 0 && !pageLoading && (
          <Flex
            style={{
              alignItems: "center",
              flexDirection: "column",
              paddingTop: 16,
            }}
          >
            <img
              src={pencilWrite}
              style={{
                height: 76,
              }}
            />
            <Text style={{ marginTop: 14, color: "gray", fontSize: 13 }}>
              {renderReaderView ? (
                <>No comments yet, be the first to add one!</>
              ) : (
                <>No comments yet</>
              )}
            </Text>
          </Flex>
        )}
        <ModalOpener
          modal={ModalRegistry.signupModal}
          modalProps={{
            subtitle: "Create a free account to join the conversation",
          }}
        >
          {(options) => (
            <Box style={{ marginLeft: -16, marginRight: -16 }}>
              {pageLoading ? (
                <StoryCommentItem noSeparator paddingBottom={false} />
              ) : (
                commentList.map((c, idx) => (
                  <StoryCommentItem
                    isLiked={c.isLiked}
                    comment={c}
                    noSeparator
                    paddingBottom={false}
                    didAttemptLike={() => options.toggleModal(true)}
                    didLikeComment={(likeChange) => {
                      // Update locally
                      c.likes = (c.likes || 0) + likeChange;
                      c.isLiked = likeChange < 0 ? false : true;

                      // Update database in various areas
                      const userId = getAuth().currentUser?.uid;
                      if (userId && c.id) {
                        const commentRef = doc(db, "storyComments", c.id);

                        // Update message count
                        setDoc(
                          commentRef,
                          {
                            likes: increment(likeChange),
                          },
                          { merge: true }
                        );

                        // Update comment likers
                        setDoc(
                          commentRef,
                          {
                            likerIds:
                              likeChange < 0
                                ? arrayRemove(userId)
                                : arrayUnion(userId),
                          },
                          { merge: true }
                        );
                      }
                    }}
                  />
                ))
              )}
            </Box>
          )}
        </ModalOpener>
      </Box>
    </Modal>
  );
};

export default MessageDetailsModal;
