import React from "react";
import Box from "../patterns/Box";
import Modal, { ModalProps } from "../modal/Modal";
import { FormControl } from "react-bootstrap";
import Text from "../patterns/Text";
import Flex from "../patterns/Flex";

export interface TextInputModalProps extends ModalProps {
  inputType?: string;
  attachmentContent?: JSX.Element;
  textLimit?: number;
  placeholder?: string;
  didSubmit?: (text: string) => void;
}

const TextInputModal = ({
  inputType,
  attachmentContent,
  textLimit,
  placeholder,
  didSubmit,
  ...restProps
}: TextInputModalProps) => {
  const [input, setInput] = React.useState<string | null>(null);
  const inputRef = React.createRef<HTMLTextAreaElement>();

  React.useEffect(() => {
    // Initial load
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Modal
      title={restProps.title}
      primaryButtonProps={{
        buttonProps: {
          text: "Add",
          disabled: !input || (input?.trim().length || 0) === 0,
          onClick: () => {
            if (input && (input?.trim().length || 0) > 0) {
              didSubmit?.(input);
            }
          },
        },
      }}
      {...restProps}
    >
      <Box style={{ width: "100%" }}>
        {textLimit && (
          <Flex
            style={{
              marginBottom: 4,
              justifyContent: textLimit ? "space-between" : undefined,
            }}
          >
            <Text variant="meta">{`${textLimit} character limit`}</Text>
            <Text
              variant="meta"
              style={{
                color: (input?.length || 0) < textLimit ? "gray" : "red",
              }}
            >{`${textLimit - (input?.length || 0)}`}</Text>
          </Flex>
        )}
        <FormControl
          maxLength={textLimit}
          type=""
          placeholder={placeholder || "Write something..."}
          value={input || ""}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            // Prevent enter key being used
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          as="textarea"
          style={{ maxHeight: 125, marginBottom: 16 }}
          ref={inputRef}
        />
        {attachmentContent}
      </Box>
    </Modal>
  );
};

export default TextInputModal;
