import React from "react";
import Box from "../patterns/Box";
import Modal, { ModalProps } from "../modal/Modal";
import { StoryCharacter } from "../messages/ChatInputField";
import CharacterListItem from "./CharacterListItem";
import {
  setDoc,
  doc,
  arrayUnion,
  getDocs,
  query,
  collection,
  orderBy,
  limit,
  startAt,
  addDoc,
  getDoc,
  updateDoc,
  FieldValue,
} from "firebase/firestore";
import { ref, uploadString } from "firebase/storage";
import { analytics, db, storage } from "../../utils/firebase";
import { Form } from "react-bootstrap";
import ButtonPill from "../patterns/ButtonPill";
import Flex from "../patterns/Flex";
import Text from "../patterns/Text";
import useStateCallback from "../../utils/useStateCallback";
import PersonalityListItem from "./PersonalityListItem";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { logEvent } from "firebase/analytics";

export interface AddCharacterModalProps extends ModalProps {
  character?: StoryCharacter;
}
export interface Personality {
  name: string;
  id: PersonalityType;
  description: string;
}

export enum PersonalityType {
  INFP = "INFP",
  ENFP = "ENFP",
  INFJ = "INFJ",
  ENFJ = "ENFJ",
  INTJ = "INTJ",
  ENTJ = "ENTJ",
  INTP = "INTP",
  ENTP = "ENTP",
  ISFP = "ISFP",
  ESFP = "ESFP",
  ISFJ = "ISFJ",
  ESFJ = "ESFJ",
  ISTP = "ISTP",
  ESTP = "ESTP",
  ISTJ = "ISTJ",
  ESTJ = "ESTJ",
}

enum AddCharacterModalTabTypes {
  inspiration = "inspo",
  create = "create",
}

const AddCharacterModal = ({
  character,
  ...restProps
}: AddCharacterModalProps) => {
  const [selectedTab, setSelectedTab] = React.useState(
    AddCharacterModalTabTypes.create
  );
  const isEditMode = character ? true : false;

  const { id } = useParams();

  const formFields: {
    label: string;
    id: string;
    placeholder?: string;
    type: "image" | "text" | "textarea" | "personality" | "date";
    required: boolean;
  }[] = [
    {
      label: "Photo",
      id: "photo",
      placeholder: "Add photo",
      type: "image",
      required: false,
    },
    {
      label: "Name",
      id: "name",
      placeholder: "Your character's name",
      type: "text",
      required: true,
    },
    {
      label: "Bio",
      id: "bio",
      placeholder: "Short bio about your character",
      type: "textarea",
      required: false,
    },
    {
      label: "Birthday",
      id: "bday",
      placeholder: "dd/mm/yyyy",
      type: "date",
      required: false,
    },
    {
      label: "Personality type",
      id: "personality",
      type: "personality",
      required: false,
    },
  ];

  const personalityTypes: Personality[] = [
    {
      name: "Idealist",
      id: PersonalityType.INFP,
      description:
        "A dreamer who wants to be true to themselves and make the world a better place.",
    },
    {
      name: "Explorer",
      id: PersonalityType.ENFP,
      description:
        "A sparkler who loves to try new things, meet new people, and have fun.",
    },
    {
      name: "Counselor",
      id: PersonalityType.INFJ,
      description:
        "A sage who seeks to understand the big picture and help others find their way.",
    },
    {
      name: "Mentor",
      id: PersonalityType.ENFJ,
      description:
        "A leader who inspires and empowers others to reach their goals and potential.",
    },
    {
      name: "Strategist",
      id: PersonalityType.INTJ,
      description: "A mastermind who values logic, competence, and innovation.",
    },
    {
      name: "Commander",
      id: PersonalityType.ENTJ,
      description:
        "A boss who leads and organizes others to achieve results and efficiency.",
    },
    {
      name: "Analyst",
      id: PersonalityType.INTP,
      description:
        "A nerd who enjoys solving problems, learning new things, and challenging assumptions.",
    },
    {
      name: "Innovator",
      id: PersonalityType.ENTP,
      description:
        "A prankster who thrives on generating ideas, debating, and experimenting.",
    },
    {
      name: "Adventurer",
      id: PersonalityType.ISFP,
      description:
        "An artist who expresses themselves through their actions, aesthetics, and values.",
    },
    {
      name: "Entertainer",
      id: PersonalityType.ESFP,
      description:
        "A party animal who lives in the moment and seeks excitement, variety, and pleasure.",
    },
    {
      name: "Protector",
      id: PersonalityType.ISFJ,
      description:
        "A helper who supports and serves others with dedication, kindness, and practicality.",
    },
    {
      name: "Host",
      id: PersonalityType.ESFJ,
      description:
        "A social butterfly who values harmony, tradition, and community.",
    },
    {
      name: "Mechanic",
      id: PersonalityType.ISTP,
      description:
        "A tinkerer who enjoys using their skills, tools, and senses to explore and improve the world.",
    },
    {
      name: "Promoter",
      id: PersonalityType.ESTP,
      description:
        "A daredevil who seeks action, challenge, and fun in every situation.",
    },
    {
      name: "Inspector",
      id: PersonalityType.ISTJ,
      description: "A worker who follows rules, standards, and facts.",
    },
    {
      name: "Director",
      id: PersonalityType.ESTJ,
      description: "A manager who enforces order, stability, and productivity.",
    },
  ];

  const defaultDate = new Date(
    new Date().getFullYear() - 21,
    new Date().getMonth(),
    new Date().getDate()
  );

  const [name, setName] = React.useState("");
  const [bio, setBio] = React.useState("");
  const [bday, setBday] = React.useState(defaultDate);

  const [day, setDay] = React.useState(bday.getDate());
  const [month, setMonth] = React.useState(bday.getMonth());
  const [year, setYear] = React.useState(bday.getFullYear());

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const findSign = (date: Date) => {
    const days = [21, 20, 21, 21, 22, 22, 23, 24, 24, 24, 23, 22];
    const signs = [
      "♒ Aquarius",
      "♓ Pisces",
      "♈ Aries",
      "♉ Taurus",
      "♊ Gemini",
      "♋ Cancer",
      "♌ Leo",
      "♍ Virgo",
      "♎ Libra",
      "♏ Scorpio",
      "♐ Sagittarius",
      "♑ Capricorn",
    ];
    let month = date.getMonth();
    let day = date.getDate();
    if (month == 0 && day <= 20) {
      month = 11;
    } else if (day < days[month]) {
      month--;
    }
    return signs[month];
  };

  const maxYear = new Date().getFullYear() - 1;
  const minYear = new Date().getFullYear() - 101;

  const [personality, setPers] = React.useState<PersonalityType | null>(null);
  const [photoURL, setPhotoURL] = React.useState("");

  React.useEffect(() => {
    logEvent(analytics, "add_character_modal_open");
  }, []);

  const NAME_CHAR_LIMIT = 40;
  const BIO_CHAR_LIMIT = 240;

  var imgUploadRef = React.createRef<HTMLInputElement>();

  const imageReader = new FileReader();
  imageReader.onload = (e) => {
    if (e.target?.result && typeof e.target?.result === "string") {
      console.log(e.target.result);
      setPhotoURL(e.target.result);
    }
  };

  const handleImageSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFile = e.target?.files?.[0];
    if (chosenFile) {
      imageReader.readAsDataURL(chosenFile);
    }
  };

  async function handleUpload(
    addCharacter?: StoryCharacter
  ): Promise<boolean | undefined> {
    if (addCharacter) {
      var selectedChars: { id: string; isReceiver: boolean }[] = [];

      if (isEditMode && addCharacter) {
        if (character) {
          console.log(character.id);
          console.log("EDITS: ", addCharacter);
        }
      } else if (addCharacter) {
        addDoc(collection(db, `characters`), {
          name: addCharacter.name,
          bio: addCharacter.bio,
          birthday: addCharacter.birthday,
          personality: addCharacter.personality,
        })
          .then((docRef) => {
            // Upload photo to storage
            if (addCharacter.photo && id) {
              const fileName = docRef.id;
              const storageRef = ref(
                storage,
                `${id}/characterProfilePhotos/${fileName}`
              );

              uploadString(
                storageRef,
                addCharacter.photo as string,
                "data_url"
              );
            }

            const user = getAuth().currentUser?.uid;
            if (user) {
              const userCharRef = doc(db, "userProfiles", user);
              updateDoc(userCharRef, {
                charactersCreated: arrayUnion(docRef.id),
              });
            }

            getDoc(doc(db, "stories", id!))
              .then((snapshot) => {
                selectedChars.push({
                  id: docRef.id,
                  isReceiver:
                    (Object.keys(snapshot.data()?.characters).length || 0) ===
                    0,
                });
                handleUploadToStory(selectedChars, id)
                  .then(() => {
                    return true;
                  })
                  .catch(() => {
                    return false;
                  });
              })
              .catch((err) => {
                return false;
              });
          })
          .catch(() => {
            return false;
          });
      } else {
        handleUploadToStory(selectedChars, id)
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      }
    } else {
      return false;
    }
  }

  async function handleUploadToStory(
    storyCharacters: { id: string; isReceiver?: boolean }[],
    storyID?: string
  ): Promise<boolean | undefined> {
    // TODO: In the future, this should warn the user if they are creating a
    // character in their story with the same name as one already in it.
    if (storyID && storyCharacters.length > 0) {
      storyCharacters.map((char) => {
        setDoc(
          doc(db, "stories", storyID),
          {
            characters: {
              [char.id]: {
                isReceiver: char.isReceiver || false,
              },
            },
          },
          { merge: true }
        );
      });
    } else {
      return false;
    }
  }

  const range = (start: number, end: number) =>
    Array.from(Array(end - start + 1).keys()).map((x) => x + start);

  return (
    <Modal
      title={isEditMode ? "Edit character" : "Create character"}
      primaryButtonProps={{
        buttonProps: {
          text: isEditMode ? "Save" : "Add",
          disabled: selectedTab === AddCharacterModalTabTypes.create && !name,
          onClick: async () => {
            return new Promise((resolve, reject) => {
              if (selectedTab === AddCharacterModalTabTypes.create) {
                handleUpload({
                  name: name,
                  photo: photoURL,
                  id: name,
                  bio: bio,
                  personality: personality || undefined,
                  birthday: bday,
                })
                  .then((res) => {
                    logEvent(analytics, "character_created");

                    console.log("RES", res);
                    if (res) {
                      console.log("Resolve");
                      resolve();
                    }
                    reject();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                handleUpload().then((res) => {
                  if (res) {
                    resolve();
                  }
                  reject();
                });
              }
            });
          },
        },
      }}
      disableXPadding
      disableYPadding
      {...restProps}
    >
      <Box style={{ padding: 16 }}>
        {formFields.map((formField, idx) => {
          return (
            <>
              {formField.type !== "image" && (
                <Flex style={{ justifyContent: "space-between" }}>
                  <Text variant="meta" style={{ marginBottom: 4 }}>
                    {formField.label}
                    {formField.required && (
                      <>
                        {" "}
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          *
                        </span>
                      </>
                    )}
                  </Text>
                  <Text variant="meta" style={{ marginBottom: 4 }}>
                    {formField.id === "name"
                      ? `${NAME_CHAR_LIMIT - name.length}`
                      : formField.id === "bio"
                      ? `${BIO_CHAR_LIMIT - bio.length}`
                      : formField.id === "personality" && !personality
                      ? "None selected"
                      : formField.id === "personality" && personality
                      ? personality
                      : formField.id === "bday" && bday
                      ? findSign(bday)
                      : null}
                  </Text>
                </Flex>
              )}

              {formField.type === "image" ? (
                <Flex
                  style={{
                    height: 80,
                    width: 80,
                    borderRadius: 100,
                    background: "rgba(0,0,0,0.025)",
                    border: !photoURL ? "1px solid lightgray" : undefined,
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 16,
                    backgroundImage: photoURL ? `url(${photoURL})` : undefined,
                    backgroundSize: "cover",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (imgUploadRef.current) {
                      console.log("IMG CLICK");
                      imgUploadRef.current.click();
                    }
                  }}
                >
                  <input
                    ref={imgUploadRef}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => handleImageSelection(e)}
                  />
                  {!photoURL && (
                    <Text variant="meta" style={{ textAlign: "center" }}>
                      {formField.placeholder}
                    </Text>
                  )}
                </Flex>
              ) : formField.type === "text" || formField.type === "textarea" ? (
                <Form.Control
                  maxLength={
                    formField.id === "name"
                      ? NAME_CHAR_LIMIT
                      : formField.id === "bio"
                      ? BIO_CHAR_LIMIT
                      : undefined
                  }
                  size="sm"
                  as={formField.type === "textarea" ? "textarea" : undefined}
                  placeholder={formField.placeholder}
                  style={{ marginBottom: 16 }}
                  value={
                    formField.id === "name"
                      ? name
                      : formField.id === "bio"
                      ? bio
                      : undefined
                  }
                  onChange={(e) => {
                    if (formField.id === "name") {
                      setName(e.target.value);
                    }
                    if (formField.id === "bio") {
                      setBio(e.target.value);
                    }
                  }}
                />
              ) : formField.type === "personality" ? (
                <>
                  {personalityTypes.map((item, idx) => (
                    <PersonalityListItem
                      personality={item}
                      interactive
                      spaceBottom={idx !== personalityTypes.length - 1}
                      selected={personality === item.id}
                      didSelect={(p) => setPers(p)}
                    />
                  ))}
                </>
              ) : formField.type === "date" ? (
                <Flex
                  style={{
                    background: "rgba(0,0,0,0.025)",
                    borderRadius: 12,
                    padding: 8,
                    marginBottom: 16,
                    border: "1px solid lightgray",
                  }}
                >
                  <Flex
                    style={{
                      flexDirection: "column",
                      flex: 1,
                      marginRight: 8,
                    }}
                  >
                    <Text variant="meta" style={{ marginBottom: 4 }}>
                      Month
                    </Text>
                    <Form.Control
                      size="sm"
                      as="select"
                      placeholder="Month"
                      value={months[month]}
                      onChange={(e) => {
                        const m = months.indexOf(e.target.value);
                        setMonth(m);
                        setBday(new Date(`${year}/${m + 1}/${day}`));
                      }}
                      style={{ borderRadius: 10 }}
                    >
                      {months.map((month) => (
                        <option value={month}>{month}</option>
                      ))}
                    </Form.Control>
                  </Flex>
                  <Flex
                    style={{
                      flexDirection: "column",
                      flex: 1,
                      marginRight: 8,
                    }}
                  >
                    <Text variant="meta" style={{ marginBottom: 4 }}>
                      Day
                    </Text>
                    <Form.Control
                      size="sm"
                      as="select"
                      placeholder="Day"
                      value={day}
                      onChange={(e) => {
                        const d = parseInt(e.target.value);
                        setDay(d);
                        setBday(new Date(`${year}/${month + 1}/${d}`));
                      }}
                      style={{ borderRadius: 10 }}
                    >
                      {range(0, new Date(year, month + 1, 0).getDate() - 1).map(
                        (day) => (
                          <option value={day + 1}>{day + 1}</option>
                        )
                      )}
                    </Form.Control>
                  </Flex>
                  <Flex style={{ flexDirection: "column", flex: 1 }}>
                    <Text variant="meta" style={{ marginBottom: 4 }}>
                      Year
                    </Text>
                    <Form.Control
                      size="sm"
                      as="select"
                      placeholder="Year"
                      value={year}
                      onChange={(e) => {
                        const y = parseInt(e.target.value);
                        setYear(y);
                        setBday(new Date(`${y}/${month + 1}/${day}`));
                      }}
                      style={{ borderRadius: 10 }}
                    >
                      {range(minYear, maxYear).map((year) => (
                        <option value={year}>{year}</option>
                      ))}
                    </Form.Control>
                  </Flex>
                </Flex>
              ) : null}
            </>
          );
        })}
      </Box>
    </Modal>
  );
};

export default AddCharacterModal;
