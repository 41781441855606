import { Toast, ToastContainer } from "react-bootstrap";
import { isDesktop, isMobile } from "react-device-detect";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";

interface ToastMessageProps {
  show: boolean;
  animationDuration?: number;
  onExit?: VoidFunction;
  message: string | JSX.Element;
  variant?: "positive" | "negative" | "neutral";
}

const ToastMessage = ({
  show,
  animationDuration = 250,
  onExit,
  message,
  variant = "neutral",
}: ToastMessageProps) => {
  const defaultStyles = {
    transition: `${animationDuration}ms ease-in-out`,
    opacity: 0,
    width: isMobile ? "100%" : undefined,
    paddingTop: isMobile ? 8 : undefined,
    paddingLeft: isMobile ? 8 : undefined,
    paddingRight: isMobile ? 8 : 16,
    paddingBottom: isDesktop ? 16 : undefined,
  };
  const animationStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };
  const initialMargin = -20;

  return createPortal(
    <Transition
      in={show}
      timeout={animationDuration}
      onExited={onExit}
      unmountOnExit
    >
      {(state) => (
        <ToastContainer
          position={isMobile ? "top-start" : "bottom-end"}
          style={{
            ...{
              zIndex: 10002,
              marginTop: isMobile
                ? state === "entered"
                  ? 0
                  : initialMargin
                : undefined,
              marginBottom: isDesktop
                ? state === "entered"
                  ? 0
                  : initialMargin
                : undefined,
            },
            ...defaultStyles,
            ...animationStyles[state],
          }}
        >
          <Toast
            bg={
              variant === "negative"
                ? "danger"
                : variant === "positive"
                ? "success"
                : "dark"
            }
            onClose={onExit}
            style={{
              width: isMobile ? "100%" : undefined,
              color: "white",
              fontWeight: 600,
              border: "none",
            }}
          >
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </Transition>,
    document.body
  );
};

export default ToastMessage;
