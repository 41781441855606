import React from "react";
import { Dropdown, DropdownButton, DropdownButtonProps } from "react-bootstrap";
import { Button, ButtonProps } from "react-bootstrap";

export interface DropButtonItemProps {
  text: string;
  id: string;
  active?: boolean;
  danger?: boolean;
  emphasize?: boolean;
}

interface DropButtonAppendButtonProps extends ButtonProps {
  content?: any;
}

export interface DropButtonProps extends Omit<DropdownButtonProps, "children"> {
  items: DropButtonItemProps[];
  appendButton?: DropButtonAppendButtonProps;
}

const DropButton = ({
  style,
  items,
  appendButton,
  className,
  ...restProps
}: DropButtonProps) => {
  return (
    <DropdownButton
      {...restProps}
      style={{
        ...style,
        fontWeight: 500,
      }}
      className={className || "dropdown-button-as"}
    >
      {items.map((item, idx) => (
        <Dropdown.Item
          key={`dropdown-item=${idx}`}
          active={item.active}
          eventKey={item.id}
          style={
            item.danger
              ? { color: "red" }
              : item.emphasize
              ? { fontWeight: 600 }
              : undefined
          }
        >
          {item.text}
        </Dropdown.Item>
      ))}
      {appendButton && (
        <Dropdown.Item
          {...appendButton}
          key={`dropdown-item-button`}
          active={false}
          as={Button}
        >
          {appendButton.content}
        </Dropdown.Item>
      )}
    </DropdownButton>
  );
};

export default DropButton;
