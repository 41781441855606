import React from "react";

import Flex from "../patterns/Flex";
import Text from "../patterns/Text";
import Box from "../patterns/Box";
import { ButtonProps, Form } from "react-bootstrap";
import ButtonPill from "../patterns/ButtonPill";
import { Personality, PersonalityType } from "./AddCharacterModal";

interface PersonalityListItemProps {
  interactive?: boolean;
  buttonProps?: ButtonProps & { text: string };
  selected?: boolean;
  renderAsCheckbox?: boolean;
  personality: Personality;
  didSelect?: (p: PersonalityType) => void;
  spaceBottom?: boolean;
}

export const getColorForPersonality = (p: PersonalityType) => {
  if (p === PersonalityType.ISTJ) {
    return "#0000cd";
  }
  if (p === PersonalityType.ISFJ) {
    return "#228b22";
  }
  if (p === PersonalityType.INFJ) {
    return "#db7093";
  }
  if (p === PersonalityType.INTJ) {
    return "#dc143c";
  }
  if (p === PersonalityType.ISTP) {
    return "#008b8b";
  }
  if (p === PersonalityType.ISFP) {
    return "#ff1493";
  }
  if (p === PersonalityType.INFP) {
    return "#ff69b4";
  }
  if (p === PersonalityType.INTP) {
    return "#ff4500";
  }
  if (p === PersonalityType.ESTP) {
    return "#ff8c00";
  }
  if (p === PersonalityType.ESFP) {
    return "#ff00ff";
  }
  if (p === PersonalityType.ENFP) {
    return "#8a2be2";
  }
  if (p === PersonalityType.ENTP) {
    return "#40e0d0";
  }
  if (p === PersonalityType.ESTJ) {
    return "#dc143c";
  }
  if (p === PersonalityType.ESFJ) {
    return "#ffb6c1";
  }
  if (p === PersonalityType.ENFJ) {
    return "#ff7f50";
  }
  return "#ff8c00";
};

const PersonalityListItem = ({
  interactive = false,
  buttonProps,
  selected = false,
  renderAsCheckbox = false,
  personality,
  didSelect,
  spaceBottom = true,
}: PersonalityListItemProps) => {
  const handleClick = () => {
    didSelect?.(personality.id);
  };

  const [hovered, setHover] = React.useState(false);

  return (
    <Flex
      style={{
        cursor: interactive ? "pointer" : undefined,
        background: hovered ? "rgba(0,0,0,0.025)" : "white",
        borderRadius: 12,
        overflow: "hidden",
        marginBottom: spaceBottom ? 8 : undefined,
        border: `2px solid ${getColorForPersonality(personality.id)}`,
      }}
      onMouseOver={interactive ? () => setHover(true) : undefined}
      onMouseLeave={interactive ? () => setHover(false) : undefined}
      onClick={interactive ? () => handleClick() : undefined}
    >
      <Flex
        style={{
          width: 60,
          background: getColorForPersonality(personality.id),
          alignItems: "center",
          justifyContent: "center",
          margin: 4,
          borderRadius: 8,
        }}
      >
        <Text
          style={{
            color: "white",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          {personality.id}
        </Text>
      </Flex>
      <Box style={{ padding: 8, flex: 1 }}>
        <Text style={{ fontSize: 14, fontWeight: "medium" }}>
          {personality.name}
        </Text>
        <Text variant="meta" style={{ marginTop: 2 }}>
          {personality.description}
        </Text>
      </Box>
      {interactive && !buttonProps ? (
        <Flex style={{ alignItems: "center", paddingRight: 8 }}>
          <Form.Check
            type={renderAsCheckbox ? "checkbox" : "radio"}
            checked={selected}
            onChange={() => handleClick()}
          />
        </Flex>
      ) : buttonProps ? (
        <Box>
          <ButtonPill {...buttonProps}>{buttonProps.text}</ButtonPill>
        </Box>
      ) : null}
    </Flex>
  );
};

export default PersonalityListItem;
