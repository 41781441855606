import React from "react";

import { getAuth } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { db } from "../../utils/firebase";
import { StoryCharacter } from "../messages/ChatInputField";
import ModalOpener from "../modal/manager/ModalOpener";
import { ModalRegistry } from "../modal/utils";
import DropButton, { DropButtonProps } from "../patterns/DropButton";
import ToastMessage from "../patterns/ToastMessage";

interface StoryOverflowButtonProps extends Omit<DropButtonProps, "items"> {
  storyTitle: string;
  storyCharacters: StoryCharacter[];
  onSelectedOption?: VoidFunction;
}

const StoryOverflowButton = ({
  storyTitle,
  storyCharacters,
  onSelectedOption,
  ...restProps
}: StoryOverflowButtonProps) => {
  const { id } = useParams();
  const [toastInfo, setToastInfo] = React.useState<{
    show: boolean;
    message: string;
    variant: "positive" | "negative" | "neutral";
  } | null>(null);

  return (
    <>
      <ModalOpener
        modal={ModalRegistry.textInputModal}
        modalProps={{
          placeholder: "Add a report reason (e.g. offensive, graphic, etc.)",
          title: "Report this story",
          textLimit: 250,
          didSubmit: (text) => {
            console.log(text);

            const loggedInUserId = getAuth().currentUser?.uid;
            const currentTime = Date.now();

            if (id) {
              const reportRef = doc(db, "storyReports", id);

              if (loggedInUserId) {
                setDoc(
                  reportRef,
                  {
                    [loggedInUserId]: {
                      time: currentTime,
                      note: text,
                    },
                  },
                  { merge: true }
                ).then(() => {
                  setToastInfo({
                    show: true,
                    message: "Submitted your report",
                    variant: "neutral",
                  });
                  setTimeout(() => {
                    setToastInfo({
                      show: false,
                      message: "Submitted your report",
                      variant: "neutral",
                    });
                  }, 5000);
                });
              } else {
                setDoc(
                  reportRef,
                  {
                    anonymousReports: {
                      [currentTime]: {
                        note: text,
                      },
                    },
                  },
                  { merge: true }
                ).then(() => {
                  setToastInfo({
                    show: true,
                    message: "Submitted your report",
                    variant: "neutral",
                  });
                  setTimeout(() => {
                    setToastInfo({
                      show: false,
                      message: "Submitted your report",
                      variant: "neutral",
                    });
                  }, 5000);
                });
              }
            }
          },
          useMinHeight: true,
        }}
      >
        {(reportModal) => (
          <ModalOpener
            modal={ModalRegistry.storyDetailsModal}
            modalProps={{
              storyTitle: storyTitle,
              characters: storyCharacters,
            }}
          >
            {(detailModal) => (
              <DropButton
                {...restProps}
                className="dropdown-no-toggle"
                onSelect={async (option) => {
                  console.log("More option: ", option);
                  if (option === "details") {
                    detailModal.toggleModal(true);
                    onSelectedOption?.();
                  }
                  if (option === "report") {
                    reportModal.toggleModal(true);
                    onSelectedOption?.();
                  }
                }}
                items={[
                  {
                    text: "Details",
                    id: "details",
                  },
                  {
                    text: "Report",
                    id: "report",
                    danger: true,
                  },
                ]}
              />
            )}
          </ModalOpener>
        )}
      </ModalOpener>
      <ToastMessage
        show={toastInfo?.show || false}
        onExit={() => setToastInfo(null)}
        message={toastInfo?.message || "Something went wrong, please try again"}
        variant={toastInfo?.variant || "neutral"}
      />
    </>
  );
};

export default StoryOverflowButton;
