import { Route, Routes } from "react-router-dom";
import React from "react";
import Create from "../pages/Create";
import Home from "../pages/Home";
import Read from "../pages/Read";
import Profile from "../pages/Profile";

const PageRoutes = () => {
  // Any one time work should be done here
  React.useEffect(() => {}, []);

  return (
    <Routes>
      <Route path="/create/:id" element={<Create />} />
      <Route path="/read/:id" element={<Read />} />
      <Route path="/profile/:id" element={<Profile />} />
      <Route path="/home" element={<Home />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default PageRoutes;
