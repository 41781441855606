import React from "react";
import Box from "../patterns/Box";
import Modal, { ModalProps } from "../modal/Modal";
import ButtonPill from "../patterns/ButtonPill";
import { Form } from "react-bootstrap";
import Text from "../patterns/Text";
import Flex from "../patterns/Flex";
import { PlusIcon } from "@primer/octicons-react";
import ModalOpener from "../modal/manager/ModalOpener";
import { ModalRegistry } from "../modal/utils";
import { getStoryCharacters } from "../../pages/Create";
import { onSnapshot, doc, getDoc, deleteField } from "firebase/firestore";
import { setDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import { StoryCharacter } from "../messages/ChatInputField";
import CharacterListItem from "../character/CharacterListItem";
import {
  IoAddCircle,
  IoAddCircleOutline,
  IoChatbubbleOutline,
  IoEyeOffOutline,
  IoEyeOutline,
  IoHeartOutline,
  IoSettingsOutline,
} from "react-icons/io5";
import DropButton from "../patterns/DropButton";
import { useParams } from "react-router-dom";
import ToastMessage from "../patterns/ToastMessage";
import { isMobile } from "react-device-detect";

export interface EditStoryModalProps extends ModalProps {
  storyTitle?: string;
  characters?: StoryCharacter[];
  didUpdateSender?: (c: StoryCharacter) => void;
  storyStats?: { title: string; number: number }[];
  isPublished?: boolean;
}

const TITLE_CHAR_LIMIT = 40;

const getIconForStat = (title: string) => {
  if (title.toLowerCase() === "views") {
    return <IoEyeOutline size={16} color="gray" />;
  }
  if (title.toLowerCase() === "likes") {
    return <IoHeartOutline size={16} color="gray" />;
  }
  if (title.toLowerCase() === "comments") {
    return <IoChatbubbleOutline size={14} color="gray" />;
  }
  return undefined;
};

export const StatsInner = ({
  storyStats,
  useBlur = false,
  isPublished = false,
}: {
  storyStats?: { title: string; number: number }[];
  useBlur?: boolean;
  isPublished: boolean;
}) => {
  const StatItem = ({
    title,
    number,
    icon,
  }: {
    title: string;
    number: number;
    icon?: JSX.Element;
  }) => {
    return (
      <Flex style={{ flex: 1, flexDirection: "column" }}>
        <Text style={{ fontSize: 26, fontWeight: 700 }}>{number}</Text>
        <Flex style={{ marginTop: 2, alignItems: "center" }}>
          {icon && <Flex style={{ marginRight: 4 }}>{icon}</Flex>}
          <Text
            variant="meta"
            style={{
              textTransform: "uppercase",
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {title}
          </Text>
        </Flex>
      </Flex>
    );
  };

  return (
    <>
      {(storyStats?.length || 0) > 0 && isPublished ? (
        storyStats?.map((s) => (
          <StatItem
            title={s.title}
            number={s.number}
            icon={getIconForStat(s.title)}
          />
        ))
      ) : (
        <Flex style={{ position: "relative", flex: 1 }}>
          <StatItem
            title="Views"
            number={0}
            icon={<IoEyeOutline size={16} color="gray" />}
          />
          <StatItem
            title="Likes"
            number={0}
            icon={<IoHeartOutline size={16} color="gray" />}
          />
          <StatItem
            title="Comments"
            number={0}
            icon={<IoChatbubbleOutline size={14} color="gray" />}
          />
          {!isPublished && useBlur && (
            <Flex
              style={{
                background: "rgba(255,255,255,0.7)",
                backdropFilter: "blur(1px)",
                position: "absolute",
                top: 0,
                left: -16,
                right: -16,
                bottom: 0,
              }}
            />
          )}
        </Flex>
      )}
    </>
  );
};

const EditStoryModal = ({
  storyTitle,
  characters,
  isVisible,
  didUpdateSender,
  storyStats,
  isPublished = false,
  ...restProps
}: EditStoryModalProps) => {
  const [title, setTitle] = React.useState(storyTitle || "");
  const [sorted, setSorted] = React.useState<StoryCharacter[]>([]);

  const [toastInfo, setToastInfo] = React.useState<{
    show: boolean;
    message: string;
    variant: "positive" | "negative" | "neutral";
  } | null>(null);

  const { id } = useParams();

  React.useEffect(() => {
    if (characters) {
      var sortedArr = characters;
      sortedArr.sort((a, b) => Number(b.isReceiver) - Number(a.isReceiver));
      setSorted(sortedArr);
    }
  }, []);

  const updateSender = async (c: StoryCharacter) => {
    var sortedArr = sorted
      .map((char) =>
        char.id == c.id
          ? { ...char, isReceiver: true }
          : { ...char, isReceiver: false }
      )
      .sort((a, b) => Number(b.isReceiver) - Number(a.isReceiver));

    setSorted(sortedArr);

    console.log("storyID " + id);
    console.log("sorted " + sorted);

    const storyRef = doc(db, "stories", `${id}`);

    sortedArr.map((c) => {
      setDoc(
        storyRef,
        { characters: { [c.id]: { isReceiver: c.isReceiver || false } } },
        { merge: true }
      ).then(() => {
        didUpdateSender?.(c);
      });
    });
  };

  return (
    <>
      <Modal
        title="Story settings"
        primaryButtonProps={{
          buttonProps: {
            text: "Save",
            onClick: async () => {
              return new Promise((resolve, reject) => {
                const storyRef = doc(db, "stories", `${id}`);

                if (id) {
                  setDoc(storyRef, { title: title }, { merge: true })
                    .then(() => resolve())
                    .catch(() => reject());
                } else {
                  reject();
                }
              });
            },
          },
        }}
        {...restProps}
      >
        <Box style={{ width: "100%" }}>
          <Flex style={{ marginBottom: 4, justifyContent: "space-between" }}>
            <Text variant="meta">Title</Text>
            <Text
              variant="meta"
              style={{
                color: title.length < TITLE_CHAR_LIMIT ? "gray" : "red",
              }}
            >{`${TITLE_CHAR_LIMIT - title.length}`}</Text>
          </Flex>
          <Form.Control
            maxLength={TITLE_CHAR_LIMIT}
            type=""
            placeholder="Your title"
            style={{ marginBottom: 16 }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          {isMobile && (
            <>
              <Box
                style={{
                  marginTop: 24,
                  paddingBottom: 4,
                  marginLeft: -16,
                  marginRight: -16,
                  borderBottom: "1px solid lightgray",
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                <Text variant="meta">
                  {isPublished ? (
                    <>Story stats</>
                  ) : (
                    <>Story stats (will be visible once published)</>
                  )}
                </Text>
              </Box>
              <Flex
                style={{
                  marginTop: 16,
                }}
              >
                <StatsInner
                  useBlur
                  storyStats={storyStats}
                  isPublished={isPublished}
                />
              </Flex>
            </>
          )}

          <Text variant="meta" style={{ marginTop: 24, marginBottom: 4 }}>
            Characters
          </Text>
          <Box
            style={{
              marginLeft: -16,
              marginRight: -16,
              borderTop: "1px solid lightgray",
              background: "rgba(0,0,0,0.01)",
            }}
          >
            {sorted?.map((c) => {
              var options = [
                {
                  text: "Edit",
                  id: "edit",
                },
                {
                  text: "Make sender",
                  id: "sender",
                },
                {
                  text: "Remove",
                  id: "remove",
                  danger: true,
                },
              ];

              if (c.isReceiver) {
                options = [
                  {
                    text: "Edit",
                    id: "edit",
                  },
                ];
              }

              return (
                <CharacterListItem
                  character={c}
                  noBackground
                  interactive
                  attachmentElement={
                    <DropButton
                      onSelect={(option) => {
                        if (option === "remove" && id) {
                          // Only let the user remove the character if they don't have any messages in the story
                          getDoc(doc(db, "stories", id)).then((snap) => {
                            const char = snap.data()?.characters[c.id];
                            if (char.messageCount || 0 > 0) {
                              // Cannot remove if the character has messages already
                              setToastInfo({
                                show: true,
                                message:
                                  "Cannot remove a character that has messages in the story",
                                variant: "negative",
                              });
                              setTimeout(() => {
                                setToastInfo({
                                  show: false,
                                  message:
                                    "Cannot remove a character that has messages in the story",
                                  variant: "negative",
                                });
                              }, 5000);
                            } else {
                              // Remove from story
                              const storyRef = doc(db, "stories", id);
                              setDoc(
                                storyRef,
                                {
                                  characters: {
                                    [c.id]: deleteField(),
                                  },
                                },
                                { merge: true }
                              ).then(() => {
                                const newArr = sorted.filter(
                                  (char) => char.id !== c.id
                                );
                                setSorted(newArr);

                                setToastInfo({
                                  show: true,
                                  message: "Removed character from this story",
                                  variant: "neutral",
                                });
                                setTimeout(() => {
                                  setToastInfo({
                                    show: false,
                                    message:
                                      "Removed character from this story",
                                    variant: "neutral",
                                  });
                                }, 5000);
                              });
                            }
                          });
                        } else if (option === "sender") {
                          updateSender(c);
                        } else if (option === "edit") {
                          // TODO
                        }
                      }}
                      size="sm"
                      variant="outline-secondary"
                      title={
                        <Flex
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            flex: 1,
                            height: 20,
                            paddingRight: 14,
                          }}
                        >
                          <IoSettingsOutline size={16} />
                        </Flex>
                      }
                      items={options}
                    />
                  }
                />
              );
            })}
          </Box>

          <Box style={{ marginTop: 16 }}>
            <ModalOpener modal={ModalRegistry.addCharacterModal}>
              {(options) => (
                <ButtonPill
                  style={{ marginRight: 4 }}
                  size="sm"
                  variant="primary"
                  onClick={() => options.toggleModal(true)}
                >
                  <Flex style={{ alignItems: "center" }}>
                    <IoAddCircleOutline size={20} style={{ marginRight: 4 }} />{" "}
                    Add character
                  </Flex>
                </ButtonPill>
              )}
            </ModalOpener>
          </Box>
        </Box>
      </Modal>
      <ToastMessage
        show={toastInfo?.show || false}
        onExit={() => setToastInfo(null)}
        message={toastInfo?.message || "Something went wrong, please try again"}
        variant={toastInfo?.variant || "neutral"}
      />
    </>
  );
};

export default EditStoryModal;
