const HOUR = 1000 * 60 * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = WEEK * 4;
const YEAR = MONTH * 12;

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const isDateInLastHour = (d: number) => {
  return d > Date.now() - HOUR;
};

const isDateInLastDay = (d: number) => {
  return d > Date.now() - DAY;
};

const isDateInLastWeek = (d: number) => {
  return d > Date.now() - WEEK;
};

const isDateInLastMonth = (d: number) => {
  return d > Date.now() - MONTH;
};

const isDateInLastYear = (d: number) => {
  return d > Date.now() - YEAR;
};

export const getShortDateString = (d: number) => {
  const date = new Date(d);
  const diff = Date.now() - date.getTime();

  if (isDateInLastHour(d)) {
    const min = Math.round(diff / (1000 * 60));
    return min <= 1 ? "Just now" : `${min}m`;
  }

  if (isDateInLastDay(d)) {
    const hour = Math.round(diff / (1000 * 60 * 24));
    return `${hour}h`;
  }

  if (isDateInLastWeek(d)) {
    return weekday[date.getDay()];
  }

  if (isDateInLastMonth(d)) {
    return month[date.getMonth()] + " " + date.getDate();
  }

  return date.getMonth() + " " + date.getDate() + " " + date.getFullYear();
};
