import { XIcon } from "@primer/octicons-react";
import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { createPortal } from "react-dom";
import Box from "../patterns/Box";
import ButtonPill from "../patterns/ButtonPill";
import Flex from "../patterns/Flex";
import Text from "../patterns/Text";
import TabContainer, { Tab, TabProps } from "../patterns/Tabs";
import DotLoader from "../messages/utils/DotLoader";

export const MODAL_MIN_HEIGHT_DESKTOP = "60vh";
export const MODAL_WIDTH_DESKTOP = "50vw";
const MODAL_BORDER_RADIUS = 12;

interface ModalButtonProps {
  buttonProps: {
    onClick: (() => Promise<void>) | (() => void);
    text: string;
    disabled?: boolean;
  };
  disableAutomaticSubmit?: boolean;
}

export interface ModalProps {
  children?: any;
  height?: string;
  title?: string;
  subtitle?: string;
  primaryButtonProps?: ModalButtonProps;
  secondaryButtonProps?: ModalButtonProps;
  headerTabs?: TabProps[];
  disableXPadding?: boolean;
  disableYPadding?: boolean;
  isVisible?: boolean;
  showOverlay?: boolean;
  hideModal?: () => void;
  useMinHeight?: boolean;
  footer?: JSX.Element;
  maxWidth?: number | string;
  zIndex?: number;
}

const Modal = ({
  children,
  height,
  title,
  subtitle,
  primaryButtonProps,
  secondaryButtonProps,
  headerTabs,
  disableXPadding = false,
  disableYPadding = false,
  showOverlay = true,
  hideModal,
  useMinHeight = false,
  footer,
  maxWidth,
  zIndex,
}: ModalProps) => {
  const rawHeight = height
    ? height
    : isMobile && !useMinHeight
    ? "100%"
    : isDesktop
    ? MODAL_MIN_HEIGHT_DESKTOP
    : undefined;

  // Loading
  const [loading, setLoading] = React.useState(false);

  // Animation
  const [animate, setAnimated] = React.useState(false);
  const animationProps = {
    bottom: !isDesktop ? 0 : undefined,
    marginTop: isDesktop ? 0 : undefined,
  };

  const headerRef = React.createRef<HTMLDivElement>();
  const [modalInnerHeight, setHeight] = React.useState<string | null>(null);

  React.useEffect(() => {
    setTimeout(() => {
      setAnimated(true);
    }, 0);
  }, []);

  React.useEffect(() => {
    if (headerRef.current && modalInnerHeight === null) {
      setHeight(`calc(${rawHeight} - ${headerRef.current.scrollHeight}px)`);
    }
  }, [headerRef]);

  // Hide and reset animation flag
  const handleHide = () => {
    setAnimated(false);
    hideModal?.();
  };

  const handlePrimaryButtonClick = async () => {
    if (primaryButtonProps?.buttonProps?.onClick instanceof Promise) {
      setLoading(true);
      primaryButtonProps?.buttonProps
        ?.onClick()
        ?.then(() => {
          setLoading(false);
          if (!primaryButtonProps?.disableAutomaticSubmit) {
            handleHide();
          }
        })
        ?.catch((err) => {
          console.log("From Modal: ", err);
          handleHide();
        });
    } else {
      primaryButtonProps?.buttonProps?.onClick();
      if (!primaryButtonProps?.disableAutomaticSubmit) {
        handleHide();
      }
    }
  };

  return createPortal(
    <Flex
      onClick={!loading ? handleHide : undefined}
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: showOverlay && isDesktop ? "rgba(0,0,0,0.4)" : undefined,
        backdropFilter: showOverlay && isDesktop ? "blur(6px)" : undefined,
        zIndex: zIndex || 10000,
        alignItems: isDesktop ? "center" : undefined,
        justifyContent: isDesktop ? "center" : undefined,
      }}
    >
      <Flex
        onClick={(e) => {
          // Prevents the overlay behind this from trigerring its onClick
          e.stopPropagation();
        }}
        style={{
          flexDirection: "column",
          minHeight: isDesktop ? MODAL_MIN_HEIGHT_DESKTOP : undefined,
          maxHeight: isDesktop ? "80vh" : "95%",
          maxWidth: isDesktop && maxWidth ? maxWidth : 650,
          transitionProperty: "bottom, margin-top",
          transitionDuration: "0.15s",
          transitionTimingFunction: "ease",
          marginTop: animate
            ? animationProps.marginTop
            : isDesktop
            ? 30
            : undefined,
          position: !isDesktop ? "absolute" : undefined,
          bottom: animate
            ? animationProps.bottom
            : !isDesktop
            ? "-50vh"
            : undefined,
          width: isDesktop ? MODAL_WIDTH_DESKTOP : "100%",
          height: rawHeight,
          overflow: "hidden",
          background: "white",
          borderTopRightRadius: MODAL_BORDER_RADIUS,
          borderTopLeftRadius: MODAL_BORDER_RADIUS,
          borderTop: isMobile ? "1px solid lightgray" : undefined,
          boxShadow: isMobile ? "0 -5px 12px rgba(0,0,0,0.15)" : undefined,
          borderBottomLeftRadius: isDesktop ? MODAL_BORDER_RADIUS : undefined,
          borderBottomRightRadius: isDesktop ? MODAL_BORDER_RADIUS : undefined,
        }}
      >
        <Box
          ref={headerRef}
          style={{
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 10,
            paddingBottom: headerTabs ? undefined : 10,
            borderBottom: "1px solid lightgray",
          }}
        >
          <Flex
            style={{
              width: "100%",
              alignItems: "center",
            }}
          >
            <ButtonPill
              onClick={!loading ? handleHide : undefined}
              variant="outline-light"
              style={{
                marginLeft: -12,
                paddingLeft: 6,
                paddingRight: 6,
                height: 40,
                color: "gray",
                border: "none",
              }}
            >
              <XIcon size={28} />
            </ButtonPill>
            <Flex style={{ flex: 1 }} />
            {primaryButtonProps?.buttonProps?.text && (
              <Flex>
                {secondaryButtonProps?.buttonProps?.text && (
                  <ButtonPill variant="outline" {...secondaryButtonProps}>
                    {secondaryButtonProps.buttonProps?.text}
                  </ButtonPill>
                )}
                <ButtonPill
                  variant="primary"
                  {...primaryButtonProps?.buttonProps}
                  onClick={
                    !loading ? () => handlePrimaryButtonClick() : undefined
                  }
                >
                  {loading ? (
                    <Flex style={{ height: 24 }}>
                      <DotLoader color="white" />
                    </Flex>
                  ) : (
                    primaryButtonProps.buttonProps?.text
                  )}
                </ButtonPill>
              </Flex>
            )}
          </Flex>
          {title && (
            <Text variant="title" style={{ marginTop: 8 }}>
              {title}
            </Text>
          )}
          {subtitle && (
            <Text style={{ marginTop: 4, fontSize: 14, color: "gray" }}>
              {subtitle}
            </Text>
          )}
          {headerTabs && (
            <TabContainer
              tabs={headerTabs}
              style={{ marginTop: 6, marginBottom: -1 }}
            />
          )}
        </Box>
        <Flex
          style={{
            flex: 1,
            overflowY: "scroll",
            paddingLeft: disableXPadding ? 0 : 16,
            paddingRight: disableXPadding ? 0 : 16,
            paddingTop: disableYPadding ? 0 : 16,
            paddingBottom: disableYPadding ? 0 : 16,
            flexDirection: "column",
          }}
        >
          {children}
        </Flex>
        {footer && <Box>{footer}</Box>}
      </Flex>
    </Flex>,
    document.body
  );
};

export default Modal;
