import React from "react";
import Box, { BoxProps } from "./Box";

interface FlexProps extends BoxProps {}

const Flex = React.forwardRef<HTMLDivElement, FlexProps>((props, ref) => {
  const { children, style, ...restProps } = props;

  return (
    <Box {...restProps} style={{ ...style, display: "flex" }} ref={ref}>
      {children}
    </Box>
  );
});

export default Flex;
