import React from "react";

import { StoryCharacter } from "../messages/ChatInputField";
import Flex from "../patterns/Flex";
import UserPhoto from "../patterns/UserPhoto";
import Text from "../patterns/Text";
import Box from "../patterns/Box";
import { ButtonProps, Form } from "react-bootstrap";
import ButtonPill from "../patterns/ButtonPill";
import { getColorForPersonality } from "./PersonalityListItem";
import { getUserPhotoPlaceholderString } from "../messages/MessageRow";

interface CharacterListItemProps {
  interactive?: boolean;
  buttonProps?: ButtonProps & { text: JSX.Element | string };
  attachmentElement?: JSX.Element;
  selected?: boolean;
  renderAsCheckbox?: boolean;
  character: StoryCharacter;
  didToggle?: (c: StoryCharacter, selected: boolean) => void;
  noBackground?: boolean;
  noSeparator?: boolean;
}

const CharacterListItem = ({
  interactive = false,
  buttonProps,
  attachmentElement,
  selected = false,
  renderAsCheckbox = false,
  character,
  didToggle,
  noBackground = false,
  noSeparator = false,
}: CharacterListItemProps) => {
  const [on, toggleOn] = React.useState(selected);
  const handleClick = () => {
    didToggle?.(character, !on);
    toggleOn(!on);
  };

  const [hovered, setHover] = React.useState(false);

  return (
    <Flex
      style={{
        padding: 16,
        borderBottom: noSeparator ? undefined : "1px solid lightgray",
        cursor: interactive ? "pointer" : undefined,
        background: hovered
          ? "rgba(0,0,0,0.025)"
          : noBackground
          ? undefined
          : "white",

        alignItems: "center",
      }}
      onMouseOver={interactive ? () => setHover(true) : undefined}
      onMouseLeave={interactive ? () => setHover(false) : undefined}
      onClick={interactive ? () => handleClick() : undefined}
    >
      <UserPhoto
        URL={character.photo}
        size={40}
        placeholderText={getUserPhotoPlaceholderString(character.name)}
      />
      <Box style={{ marginLeft: 10, flex: 1 }}>
        <Flex style={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text>{character.name}</Text>
          {character.isReceiver && (
            <Flex
              style={{
                padding: 4,
                borderRadius: 6,
                background: "gray",
                marginLeft: 6,
              }}
            >
              <Text
                style={{
                  textTransform: "uppercase",
                  fontSize: 11,
                  color: "white",
                  fontWeight: "600",
                }}
              >
                Sender
              </Text>
            </Flex>
          )}
        </Flex>

        <Flex
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: 2,
          }}
        >
          <Flex style={{ flex: 1, flexDirection: "column" }}>
            <Text style={{ fontSize: 13, color: "gray" }}>
              {character.bio || "No bio added yet"}
            </Text>
            {character.personality && (
              <Box
                style={{
                  padding: 4,
                  borderRadius: 6,
                  background: getColorForPersonality(character.personality),
                  marginTop: 6,
                  width: "min-content",
                }}
              >
                <Text
                  style={{
                    textTransform: "uppercase",
                    fontSize: 11,
                    color: "white",
                    fontWeight: "600",
                  }}
                >
                  {character.personality}
                </Text>
              </Box>
            )}
          </Flex>
          {attachmentElement ? (
            attachmentElement
          ) : interactive && buttonProps ? (
            <Box>
              <ButtonPill {...buttonProps}>{buttonProps.text}</ButtonPill>
            </Box>
          ) : null}
        </Flex>
      </Box>
    </Flex>
  );
};

export default CharacterListItem;
