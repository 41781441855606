import { Link, useParams } from "react-router-dom";
import Box, { BoxProps } from "./patterns/Box";
import Flex from "./patterns/Flex";
import logo from "../../src/assets/logo.svg";
import Text from "./patterns/Text";
import React from "react";
import { getAuth } from "firebase/auth";
import ModalOpener from "./modal/manager/ModalOpener";
import { ModalRegistry } from "./modal/utils";
import ButtonPill from "./patterns/ButtonPill";
import UserPhoto from "./patterns/UserPhoto";
import { getUserPhotoPlaceholderString } from "./messages/MessageRow";
import {
  IoAirplane,
  IoChevronForward,
  IoEye,
  IoHelpBuoyOutline,
  IoHelpCircleOutline,
  IoInformationCircleOutline,
  IoPaperPlane,
  IoPaperPlaneOutline,
  IoWarning,
} from "react-icons/io5";
import { XIcon } from "@primer/octicons-react";
import { isDesktop } from "react-device-detect";
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../utils/firebase";
import Banner from "./patterns/Banner";
import bubbles from "../../src/assets/bubbles.png";
import { doc, setDoc } from "firebase/firestore";
import ToastMessage from "./patterns/ToastMessage";

export interface PaneDetailRowProps extends BoxProps {
  text?: string;
  subText?: string;
  icon: JSX.Element;
  variant?: "regular" | "danger" | "bold";
  size?: "small" | "regular";
  attachmentElement?: JSX.Element;
  loading?: boolean;
}

export interface StoryDetailsPaneProps {
  id: string;
}

interface UserPopoverInnerProps {
  storyId?: string;
  onClose?: (modalToOpen?: "signUp" | "sendMessage") => void;
}

const X_PADDING = 12;

export const PaneDetailRow = ({
  loading = false,
  text,
  subText,
  icon,
  variant = "regular",
  size = "regular",
  onClick,
  style,
  attachmentElement,
  ...restProps
}: PaneDetailRowProps) => {
  return (
    <Flex
      onClick={onClick}
      style={{
        alignItems: "center",
        cursor: onClick ? "pointer" : undefined,
        paddingTop: 4,
        paddingBottom: 4,
        ...style,
      }}
      {...restProps}
    >
      {icon}
      <Flex
        style={{
          flexDirection: "column",
          flex: 1,
          marginLeft: 10,
          marginRight: attachmentElement ? 10 : undefined,
        }}
      >
        <Text
          style={{
            borderRadius: loading ? 100 : undefined,
            background: loading ? "rgba(0,0,0,0.1)" : undefined,
            fontWeight: onClick || variant === "bold" ? 600 : undefined,
            fontSize: size === "small" ? 14 : undefined,
            color: loading
              ? "transparent"
              : variant === "danger"
              ? "red"
              : variant === "bold"
              ? "black"
              : "black",
            width: loading ? "50%" : undefined,
          }}
        >
          {loading ? "Author name" : text}
        </Text>
        {(subText || loading) && (
          <Text
            style={{
              borderRadius: loading ? 100 : undefined,
              background: loading ? "rgba(0,0,0,0.05)" : undefined,
              fontSize: 13,
              color: loading ? "transparent" : "gray",
              marginTop: 2,
              width: loading ? "25%" : undefined,
            }}
          >
            {subText}
          </Text>
        )}
      </Flex>
      {attachmentElement}
    </Flex>
  );
};

const StoryDetailsPane = ({ id }: StoryDetailsPaneProps) => {
  const [views, setViews] = React.useState(0);

  React.useEffect(() => {}, []);

  return (
    <Flex
      style={{
        flex: 1,
        paddingLeft: X_PADDING,
        paddingRight: X_PADDING,
        paddingTop: 8,
        paddingBottom: 8,
        flexDirection: "column",
      }}
    >
      <PaneDetailRow
        icon={<IoEye color="gray" size={18} />}
        text={`${views} ${views === 1 ? "view" : "views"}`}
      />
      <PaneDetailRow
        icon={<IoWarning color="red" size={18} />}
        text="Report"
        variant="danger"
      />
    </Flex>
  );
};

const UserPopoverInner = ({ storyId, onClose }: UserPopoverInnerProps) => {
  var auth = getAuth();
  var user = auth.currentUser;

  var isLoggedIn = user !== null;

  const [imgURL, setImgURL] = React.useState<string | undefined>(undefined);
  if (auth.currentUser?.uid) {
    const profilePhotoRef = ref(
      storage,
      `profilePhotos/${auth.currentUser?.uid}`
    );
    getDownloadURL(profilePhotoRef).then((url) => {
      setImgURL(url);
    });
  }

  const { id } = useParams();
  const [toastInfo, setToastInfo] = React.useState<{
    show: boolean;
    message: string;
    variant: "positive" | "negative" | "neutral";
  } | null>(null);

  return (
    <>
      <Flex style={{ height: "100%", flexDirection: "column" }}>
        <Flex
          style={{
            paddingLeft: X_PADDING,
            paddingRight: X_PADDING,
            paddingTop: 16,
            paddingBottom: 16,
            alignItems: "center",
            justifyContent: onClose ? "space-between" : undefined,
          }}
        >
          <Link to="/">
            <img
              src={logo}
              style={{ height: isDesktop ? 34 : 30, marginRight: 6 }}
            />
          </Link>
          {onClose && (
            <ButtonPill
              variant="outline-light"
              style={{
                marginRight: -6,
                paddingLeft: 6,
                paddingRight: 6,
                height: 40,
                color: "gray",
                border: "none",
              }}
              onClick={() => onClose?.()}
            >
              <XIcon size={28} />
            </ButtonPill>
          )}
        </Flex>

        <Flex
          style={{
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Box>
            <Flex
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: 4,
              }}
            >
              <Text
                variant="meta"
                style={{
                  paddingLeft: X_PADDING,
                  paddingRight: X_PADDING,
                }}
              >
                Your profile
              </Text>
            </Flex>
            {isLoggedIn ? (
              <PaneDetailRow
                variant="bold"
                icon={
                  <UserPhoto
                    size={32}
                    URL={imgURL}
                    placeholderText={
                      auth.currentUser?.displayName
                        ? getUserPhotoPlaceholderString(
                            auth.currentUser?.displayName
                          )
                        : undefined
                    }
                  />
                }
                text={auth.currentUser?.displayName || undefined}
                style={{
                  paddingLeft: X_PADDING,
                  paddingRight: X_PADDING,
                  paddingTop: 8,
                  paddingBottom: 8,
                }}
                attachmentElement={<IoChevronForward size={18} color="gray" />}
                onClick={() => {
                  if (auth.currentUser?.uid) {
                    window.open(`/profile/${auth.currentUser.uid}`, "_self");
                  }
                }}
              />
            ) : (
              <Box
                style={{
                  marginLeft: X_PADDING,
                  marginRight: X_PADDING,
                  marginTop: X_PADDING,
                }}
              >
                <Banner
                  dismissible={false}
                  title="Create an account"
                  subtitle="An account allows you to save stories and participate in the conversation!"
                  image={<img src={bubbles} style={{ height: 60 }} />}
                  attachmentElement={
                    <ButtonPill
                      style={{ width: "100%", marginTop: 12 }}
                      onClick={() => {
                        onClose?.("signUp");
                      }}
                    >
                      Create a free account
                    </ButtonPill>
                  }
                />
              </Box>
            )}
          </Box>

          <Box>
            <Flex
              style={{
                borderBottom: "1px solid lightgray",
                paddingBottom: 4,
              }}
            >
              <Text
                variant="meta"
                style={{
                  paddingLeft: X_PADDING,
                  paddingRight: X_PADDING,
                }}
              >
                Send us a message
              </Text>
            </Flex>
            <Box
              style={{
                paddingLeft: X_PADDING,
                paddingRight: X_PADDING,
                paddingTop: X_PADDING,
                paddingBottom: X_PADDING,
              }}
            >
              <Text>Give feedback, get help, or make a suggestion</Text>
              <ButtonPill
                style={{ width: "100%", marginTop: 12 }}
                onClick={() => {
                  onClose?.("sendMessage");
                }}
              >
                <Flex
                  style={{
                    alignItems: "center",
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <IoHelpCircleOutline size={24} />
                  <Flex style={{ justifyContent: "center", marginLeft: 4 }}>
                    Send a message
                  </Flex>
                </Flex>
              </ButtonPill>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <ToastMessage
        show={toastInfo?.show || false}
        onExit={() => setToastInfo(null)}
        message={toastInfo?.message || "Something went wrong, please try again"}
        variant={toastInfo?.variant || "neutral"}
      />
    </>
  );
};

export default UserPopoverInner;
