import ButtonPill from "../patterns/ButtonPill";
import Flex from "../patterns/Flex";
import React from "react";
import { ButtonProps } from "react-bootstrap";
import {
  IoChevronForward,
  IoEllipsisHorizontal,
  IoPause,
  IoPerson,
  IoPersonOutline,
  IoPlayOutline,
  IoShare,
  IoShareOutline,
} from "react-icons/io5";
import Text from "../patterns/Text";
import { PersonIcon, ShareIcon } from "@primer/octicons-react";
import Box from "../patterns/Box";
import StoryOverflowButton from "../story/StoryOverflowButton";
import { StoryCharacter } from "./ChatInputField";
import UserPhoto from "../patterns/UserPhoto";
import { getUserPhotoPlaceholderString } from "./MessageRow";

interface ReaderActionBarProps extends ButtonProps {
  paused?: boolean;
  completed?: boolean;
  previewMode?: boolean;
  storyTitle: string;
  storyCharacters: StoryCharacter[];
  didSelectDropdownOption?: VoidFunction;
  storyInfo: {
    authorName?: string;
    messageCount?: number;
    likeCount?: number;
    commentCount?: number;
  };
}

const ReaderActionBar = React.forwardRef<HTMLElement, ReaderActionBarProps>(
  (props, ref) => {
    const {
      style,
      paused = false,
      completed,
      previewMode = false,
      storyTitle,
      storyCharacters,
      didSelectDropdownOption,
      storyInfo,
      ...restProps
    } = props;

    return (
      <Flex style={{ flexDirection: "column" }}>
        {previewMode && (
          <Box style={{ paddingBottom: 16 }}>
            <Text style={{ fontSize: 18 }}>
              <b>{storyTitle}</b>
              {storyInfo.authorName && (
                <>, a chat story by {storyInfo.authorName}</>
              )}
            </Text>
            <Flex style={{ marginTop: 6 }}>
              <Text style={{ fontSize: 13, color: "gray" }}>
                {storyInfo.messageCount || 0}{" "}
                {(storyInfo.messageCount || 0) === 1 ? "message" : "messages"} ·{" "}
                {storyInfo.likeCount || 0}{" "}
                {(storyInfo.likeCount || 0) === 1 ? "like" : "likes"} ·{" "}
                {storyInfo.commentCount || 0}{" "}
                {(storyInfo.commentCount || 0) === 1 ? "comment" : "comments"}
              </Text>
            </Flex>
          </Box>
        )}
        <Flex>
          <ButtonPill
            {...restProps}
            ref={ref as React.RefObject<HTMLElement>}
            variant={paused || previewMode ? "primary" : "outline-secondary"}
            size="lg"
            style={{
              ...style,
              flex: 1,
              fontSize: 16,
            }}
          >
            <Flex
              style={{
                alignItems: "center",
                flex: 1,
                justifyContent: "center",
              }}
            >
              {paused ? <IoPlayOutline size={24} /> : <IoPause size={24} />}
              <Flex style={{ justifyContent: "center", marginLeft: 4 }}>
                {previewMode ? (
                  <>Start reading</>
                ) : paused ? (
                  <>Resume autoplay</>
                ) : (
                  <>Pause autoplay</>
                )}
              </Flex>
            </Flex>
          </ButtonPill>
          <StoryOverflowButton
            onSelectedOption={didSelectDropdownOption}
            size="lg"
            storyTitle={storyTitle}
            storyCharacters={storyCharacters}
            style={{
              marginLeft: 10,
              border: "1px solid lightgray",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 100,
              display: "flex",
            }}
            title={
              <Flex
                style={{
                  paddingLeft: 8,
                  paddingRight: 8,
                  flex: 1,
                  height: 40,
                  alignItems: "center",
                }}
              >
                <IoEllipsisHorizontal size={24} color="#6c757d" />
              </Flex>
            }
          />
        </Flex>
      </Flex>
    );
  }
);

export default ReaderActionBar;
