import React from "react";

import { isDesktop } from "react-device-detect";
import { scrollIntoView } from "seamless-scroll-polyfill";

import Page from "../components/Page";
import ChatHeader from "../components/ChatHeader";
import { MessageRow } from "../components/messages/MessageRow";
import Flex from "../components/patterns/Flex";
import Box from "../components/patterns/Box";
import Text from "../components/patterns/Text";
import { Container } from "react-bootstrap";
import { ChatMessage } from "./Create";
import ReaderActionBar from "../components/messages/ReaderActionBar";
import { StoryCharacter } from "../components/messages/ChatInputField";
import { getStoryCharacters } from "./Create";
import { db, analytics, storage } from "../utils/firebase";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  increment,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import CharacterListItem from "../components/character/CharacterListItem";
import { useParams } from "react-router-dom";
import Banner from "../components/patterns/Banner";
import bubbles from "../../src/assets/bubbles.png";
import { getAuth, User } from "firebase/auth";
import {
  IoEllipsisHorizontal,
  IoPersonOutline,
  IoShareOutline,
} from "react-icons/io5";
import { logEvent } from "firebase/analytics";

import StoryOverflowButton from "../components/story/StoryOverflowButton";
import ModalOpener from "../components/modal/manager/ModalOpener";
import { ModalRegistry } from "../components/modal/utils";
import { getDownloadURL, ref } from "firebase/storage";

export const AUTOMATIC_MESSAGE_SEND_INTERVAL = 2000; // 2 seconds
export const delay = (t: number) =>
  new Promise((resolve) => setTimeout(resolve, t));

export async function getChatMessageArrayFromFirebase(
  storyID: string
): Promise<ChatMessage[]> {
  return new Promise((res, reject) => {
    var storyDic: any = {};

    const docRef = doc(db, "stories", `${storyID}`);
    getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          storyDic = docSnap.data();

          if (docSnap.data().characters !== undefined) {
            const charsToGet: { id: string; isReceiver: boolean }[] = [];
            Object.keys(docSnap.data()?.characters).map((charKey: any) => {
              const char = docSnap.data()?.characters?.[charKey];
              charsToGet.push({ id: charKey, isReceiver: char.isReceiver });
            });

            getStoryCharacters(charsToGet, storyID)
              .then((characters) => {
                if (characters) {
                  storyDic.characters = characters;
                }

                const messageRef = doc(db, "storyMessages", `${storyID}`);
                getDoc(messageRef)
                  .then((messageSnap) => {
                    if (messageSnap.exists()) {
                      storyDic.messages = messageSnap.data();
                      convertDatabaseToMessage(storyDic, storyID)
                        .then((chats) => {
                          return res(chats);
                        })
                        .catch(() => {
                          return reject();
                        });
                    }
                  })
                  .catch(() => {
                    return reject();
                  });
              })
              .catch(() => {
                return reject();
              });
          }
        }
      })
      .catch(() => {
        return reject();
      });
  });
}

async function convertDbToMessage(
  message: any,
  characters: any,
  storyID: string
): Promise<ChatMessage> {
  const userId = getAuth().currentUser?.uid;

  return new Promise((res, reject) => {
    var sections: React.ReactNode[] = [];
    var renderAsImage: boolean = false;
    var imageSrc: string | undefined = undefined;

    var loggedInUserLiked = false;
    if (userId && message.likerIds?.includes?.(userId)) {
      loggedInUserLiked = true;
    }

    const messageCharacters: StoryCharacter[] = characters;

    if (!message["imageName"]) {
      const dbMessageStrings: [string] = message["messageStrings"];
      for (var messageString of dbMessageStrings) {
        if (messageString.includes("data:image")) {
          sections.push(messageString);
          renderAsImage = true;
        } else if (messageString.trim().includes("\\n")) {
          sections.push(<br></br>);
        } else {
          sections.push(<>{messageString.trim()}</>);
        }
      }

      const newMessage = {
        character: messageCharacters.find((c) => c.id === message.characterID),
        message: sections,
        renderAsImageMessage: !!imageSrc,
        imageSrc: imageSrc,
        isLiked: loggedInUserLiked,
        likeCount: message.likeCount || 0,
        commentsCount: message.commentsCount || 0,
      };

      return res(newMessage);
    } else {
      const fileName = message["imageName"];
      const fileRef = ref(storage, `${storyID}/${fileName}`);

      getDownloadURL(fileRef)
        .then((url) => {
          imageSrc = url;

          const newMessage = {
            character: messageCharacters.find(
              (c) => c.id === message.characterID
            ),
            message: sections,
            renderAsImageMessage: !!imageSrc,
            imageSrc: imageSrc,
            isLiked: loggedInUserLiked,
            likeCount: message.likeCount || 0,
            commentsCount: message.commentsCount || 0,
          };

          return res(newMessage);
        })
        .catch(() => {
          return reject();
        });
    }
  });
}

export const convertDatabaseToMessage = (
  dbDic: any,
  storyID: string
): Promise<ChatMessage[]> => {
  const promises: Promise<ChatMessage>[] = [];

  if ("messages" in dbDic) {
    const dbMessages: any = dbDic["messages"];

    for (const messageKey of Object.keys(dbMessages)) {
      const message = dbMessages[messageKey];
      promises.push(convertDbToMessage(message, dbDic.characters, storyID));
    }
  }

  return Promise.all(promises);
};

const Read = () => {
  const [readMessages, setReadCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [showBanner, setShowBanner] = React.useState(false);
  const [messages, setMessages] = React.useState<ChatMessage[]>([]);
  const [title, setTitle] = React.useState<string>("Group title");
  const [storyCharacters, setCharacters] = React.useState<StoryCharacter[]>([]);
  const [author, setAuthor] = React.useState<string | null>(null);

  const [likeCount, setLikeCount] = React.useState(0);
  const [commentCount, setCommentCount] = React.useState(0);

  const [authorName, setAuthorName] = React.useState<string | null>(null);
  const [authorStoryCount, setAuthorStoryCount] = React.useState<number>(0);
  const [user, setUser] = React.useState<User | null>(null);

  const [paused, setPaused] = React.useState(true);
  const [pageLoading, setPageLoading] = React.useState(true);

  const [showPreview, setShowPreview] = React.useState(true);

  const { id } = useParams();

  // Refs
  var bottomMessageRef = React.createRef<HTMLDivElement>();
  var setProfileView = false;
  var setView = false;
  var initialLoad = true;

  // Happens on page load
  React.useEffect(() => {
    setLoading(true);

    if (!id) {
      window.open("/home", "_self");
    }

    getAuth().onAuthStateChanged((user) => {
      const storyRef = doc(db, "stories", id!);

      // Load data
      getDoc(storyRef).then((snapshot) => {
        const author = snapshot.data()?.author;

        setTitle(snapshot.data()?.title);
        setLikeCount(snapshot.data()?.totalLikes);
        setCommentCount(snapshot.data()?.totalComments);

        // Check if story is published
        if (!(snapshot.data()?.published || false) && author !== user?.uid) {
          // TODO Maybe add an error message here
          window.open("/home", "_self");
        }

        setUser(user);
        setAuthor(author);

        const authorRef = doc(db, "userProfiles", author);
        getDoc(authorRef).then((snap) => {
          const name = snap.data()?.displayName;
          const authStories = snap.data()?.totalStories;
          setAuthorName(name);
          if (authStories > 1) {
            setAuthorStoryCount(authStories - 1);
          }
        });

        loadMessages(id!).then(() => {
          if (!setView) {
            // Increment view count on story. No unique view counter for now, will increment on every load.
            updateDoc(storyRef, { views: increment(1) });
            logEvent(analytics, "story_view");
            setView = true;
          }

          // Add a view to the creator's profile (total view count across stories)
          if (snapshot.data()?.author && !setProfileView) {
            const creatorRef = doc(db, "userProfiles", snapshot.data()?.author);
            updateDoc(creatorRef, { totalViews: increment(1) });
            setProfileView = true;
          }

          // If cookie exists, use it to skip to that message
          const attemptedLikeIdx = window.localStorage.getItem(
            "lastAttemptedLikeIndex"
          );
          const attemptedLikeStory = window.localStorage.getItem(
            "lastAttemptedLikeStory"
          );
          const attemptedLikeTime = window.localStorage.getItem(
            "lastAttemptedLikeTime"
          );

          if (attemptedLikeIdx && attemptedLikeStory && attemptedLikeTime) {
            const h = 1000 * 60 * 60;
            if (Date.now() - h < new Date(attemptedLikeTime).getTime()) {
              if (id === attemptedLikeStory) {
                setReadCount(parseInt(attemptedLikeIdx) || 0);
                // Clear the storage after use
                window.localStorage.clear();
              }
            } else {
              // Clear the old storage
              window.localStorage.clear();
            }
          }

          const charsToGet: { id: string; isReceiver: boolean }[] = [];
          Object.keys(snapshot.data()?.characters).map((charKey: any) => {
            const char = snapshot.data()?.characters?.[charKey];
            if (storyCharacters?.find((c) => c.id === charKey) === undefined) {
              charsToGet.push({ id: charKey, isReceiver: char.isReceiver });
            }
          });

          if (charsToGet.length > 0 && id) {
            getStoryCharacters(charsToGet, id).then((characters) => {
              if (characters) {
                characters.sort(
                  (a, b) => Number(b.isReceiver) - Number(a.isReceiver)
                );
                setCharacters([...storyCharacters, ...characters]);
              }
              if (initialLoad) {
                setPageLoading(false);
                initialLoad = false;
              }
            });
          }
        });
      });
    });
  }, []);

  React.useEffect(() => {
    setLoading(false);
    // sendNextMessage(1);
  }, [messages]);

  // Happens when messages are sent
  React.useEffect(() => {
    if (bottomMessageRef.current) {
      scrollIntoView(bottomMessageRef.current, {
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });
    }

    if (!loading && !paused) {
      delay(AUTOMATIC_MESSAGE_SEND_INTERVAL / 2).then(() => {
        sendNextMessage(readMessages + 1);
      });
    }
  }, [loading]);

  React.useEffect(() => {
    if (bottomMessageRef.current) {
      scrollIntoView(bottomMessageRef.current, {
        block: "nearest",
        inline: "center",
        behavior: "smooth",
      });
    }
  }, [readMessages]);

  async function loadMessages(storyID: string): Promise<void> {
    const messageChats = await getChatMessageArrayFromFirebase(storyID);
    setMessages(messageChats);
  }

  const getReaderMessageRow = (
    message: ChatMessage,
    idx: number,
    loading?: boolean,
    isPreview?: boolean
  ) => {
    const readCountToUse = isPreview ? messages.length : readMessages;
    const showPhoto =
      message.character &&
      !(
        readCountToUse > idx + 1 &&
        message.character.id === messages[idx + 1].character?.id
      );
    const showName =
      message.character &&
      !(idx > 0 && message.character.id === messages[idx - 1].character?.id);

    return (
      <ModalOpener
        modal={ModalRegistry.signupModal}
        modalProps={{
          subtitle: "Create a free account to join the conversation",
        }}
      >
        {(options) => (
          <MessageRow
            blur={isPreview}
            index={idx}
            renderReaderView
            key={`message-${idx}`}
            likes={message.likeCount}
            commentsCount={message.commentsCount}
            character={message.character}
            showPhoto={showPhoto}
            showName={showName}
            showLoadingDots={loading}
            renderAsImageMessage={message.renderAsImageMessage}
            imageSrc={message.imageSrc}
            isLiked={message.isLiked || false}
            didAttemptLike={() => {
              logEvent(analytics, "story_like_attempt");

              // User has to log in to like. Save data for when they finish signing up
              if (id) {
                window.localStorage.setItem(
                  "lastAttemptedLikeTime",
                  `${Date.now()}`
                );
                window.localStorage.setItem("lastAttemptedLikeStory", id);
                window.localStorage.setItem("lastAttemptedLikeIndex", `${idx}`);
              }

              // Show sign up modal
              options.toggleModal(true);
            }}
            didLikeMessage={(likeChange) => {
              logEvent(analytics, "story_like");

              // Update locally
              message.likeCount = (message.likeCount || 0) + likeChange;
              message.isLiked = likeChange < 0 ? false : true;

              // Update database in various areas
              const userId = getAuth().currentUser?.uid;
              if (id && userId) {
                const messageRef = doc(db, "storyMessages", id);

                // Update message count
                setDoc(
                  messageRef,
                  {
                    [idx + 1]: {
                      likeCount: increment(likeChange),
                    },
                  },
                  { merge: true }
                );

                // Update story total likes
                const storyRef = doc(db, "stories", id);
                updateDoc(storyRef, { totalLikes: increment(likeChange) });

                // Update author profile's total likes
                if (author) {
                  const profileRef = doc(db, "userProfiles", author);
                  updateDoc(profileRef, { totalLove: increment(likeChange) });
                }

                // Update message likers
                setDoc(
                  messageRef,
                  {
                    [idx + 1]: {
                      likerIds:
                        likeChange < 0
                          ? arrayRemove(userId)
                          : arrayUnion(userId),
                    },
                  },
                  { merge: true }
                );
              }
            }}
            didSubmitComment={(comment) => {
              logEvent(analytics, "story_comment");

              message.commentsCount = (message.commentsCount || 0) + 1;

              if (user?.uid && id) {
                const commentRef = collection(db, "storyComments");
                addDoc(commentRef, {
                  text: comment,
                  author: user.uid,
                  likes: 0,
                  time: Date.now(),
                }).then((docRef) => {
                  const storyRef = doc(db, "storyMessages", id);
                  setDoc(
                    storyRef,
                    {
                      [idx + 1]: {
                        commentIds: arrayUnion(docRef.id),
                        commentsCount: increment(1),
                      },
                    },
                    { merge: true }
                  );
                });
              }
            }}
            didOpenMessageModal={() => {
              setPaused(true);
            }}
          >
            {message.message}
          </MessageRow>
        )}
      </ModalOpener>
    );
  };

  function sendNextMessage(index: number) {
    if (index > messages.length) return;

    if (index === messages.length) {
      const storyRef = doc(db, "stories", id!);

      // Increment completions on story. No unique completions for now, will increment on every completion.
      updateDoc(storyRef, { completions: increment(1) });
    }

    setLoading(true);
    setReadCount(index);

    // Loading time is weighted by message length, with a minimum
    const loadingTime =
      AUTOMATIC_MESSAGE_SEND_INTERVAL +
      (!messages[readMessages]?.renderAsImageMessage
        ? messages[readMessages].message?.toString().length || 0
        : 0) *
        15;

    delay(loadingTime).then(() => {
      setLoading(false);
    });
  }

  function startReading() {
    if (showPreview) {
      setShowPreview(false);
      setPaused(false);
      sendNextMessage(1);
    }
  }

  return (
    <Page
      noOverflow={showPreview}
      pageTitle="Read | Tiny Fiction"
      showNav={false}
      secondaryCol={
        isDesktop && (
          <Box style={{ paddingTop: 16, paddingBottom: 16 }}>
            <Box
              style={{
                background: "rgba(0,0,0,0.025)",
                borderRadius: 12,
                overflow: "hidden",
                border: "0.5px solid lightgray",
              }}
            >
              <Box
                style={{
                  padding: 16,
                  borderBottom: "1px solid lightgray",
                }}
              >
                <Text style={{ fontSize: 16, fontWeight: 600 }}>
                  Characters
                </Text>
              </Box>

              {(storyCharacters?.length || 0) > 0 ? (
                <>
                  {storyCharacters?.map((character, idx) => (
                    <CharacterListItem
                      character={character}
                      interactive={false}
                      noBackground
                      noSeparator={idx === storyCharacters?.length - 1}
                      key={character.id}
                    />
                  ))}
                </>
              ) : (
                <Box style={{ padding: 16 }}>
                  <Text variant="meta">Nothing to see here</Text>
                </Box>
              )}
            </Box>
          </Box>
        )
      }
      stickyHeader={
        !isDesktop ? (
          <ChatHeader
            enablePublish
            title={title}
            id={id}
            characters={storyCharacters}
            showLoadingState={pageLoading}
            authId={author || undefined}
            authName={authorName || undefined}
            authStoryCount={authorStoryCount}
          />
        ) : undefined
      }
      stickyFooter={
        !isDesktop && readMessages < messages.length ? (
          <Container
            style={{
              borderTop: "1px solid lightgray",
              boxShadow: "0 -2px 4px rgba(0,0,0,0.05)",
              paddingTop: 14,
              paddingBottom: 14,
            }}
          >
            <ReaderActionBar
              storyInfo={{
                authorName: authorName || undefined,
                messageCount: messages.length,
                likeCount: likeCount,
                commentCount: commentCount,
              }}
              previewMode={showPreview}
              storyCharacters={storyCharacters}
              storyTitle={title}
              completed={readMessages >= messages.length}
              paused={paused}
              id={id}
              onClick={() => {
                if (showPreview) {
                  startReading();
                } else if (!loading && paused) {
                  sendNextMessage(readMessages + 1);
                } else {
                  setPaused(!paused);
                }

                if (paused) {
                  logEvent(analytics, "unpaused_autoplay");
                } else {
                  logEvent(analytics, "paused_autoplay");
                }
              }}
              didSelectDropdownOption={() => setPaused(true)}
            />
          </Container>
        ) : undefined
      }
    >
      <Flex
        style={{
          height: isDesktop ? "100vh" : "100%",
          flexDirection: "column",
          flexGrow: 1,
          borderLeft: isDesktop ? "1px solid lightgray" : undefined,
          borderRight: isDesktop ? "1px solid lightgray" : undefined,
        }}
      >
        {isDesktop && (
          <ChatHeader
            enablePublish
            title={title}
            id={id}
            characters={storyCharacters}
            showLoadingState={pageLoading}
            authId={author || undefined}
            authName={authorName || undefined}
            authStoryCount={authorStoryCount}
          />
        )}
        <Container
          style={{
            paddingLeft: !isDesktop ? 0 : undefined,
            paddingRight: !isDesktop ? 0 : undefined,
            paddingTop: 14,
            flexGrow: 1,
            overflowY: "scroll",
            position: "relative",
          }}
        >
          {showBanner && (
            <Banner
              title="Welcome to Tiny Fiction!"
              subtitle="A place to create and share chat-based stories. Chats autoplay by default, but you can pause with the button below."
              image={<img src={bubbles} style={{ height: 74 }} />}
              onClick={() => setShowBanner(false)}
            />
          )}
          {messages
            .slice(0, showPreview ? messages.length : readMessages)
            .map((message, idx) => {
              return getReaderMessageRow(
                message,
                idx,
                idx === readMessages - 1 && loading,
                showPreview && idx > 0
              );
            })}
          {readMessages >= messages.length && !pageLoading && (
            <Box style={{ width: "100%", paddingBottom: 22, paddingTop: 8 }}>
              <Flex style={{ alignItems: "center" }}>
                <Flex style={{ height: 1, flex: 1, background: "lightgray" }} />
                <Text
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  End
                </Text>
                <Flex style={{ height: 1, flex: 1, background: "lightgray" }} />
              </Flex>
              <Flex style={{ marginTop: 22 }}>
                <Flex
                  style={{
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Flex
                    style={{
                      height: 50,
                      width: 50,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 100,
                      background: "rgba(0,0,0,0.05)",
                      border: "0.5px solid lightgray",
                      color: "#383838",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      logEvent(analytics, "story_share_click");
                      try {
                        await navigator.share({
                          title: `Read ${title} on Tiny Fiction`,
                          text: `Read ${title} on Tiny Fiction`,
                          url: window.location.href,
                        });
                      } catch (err) {
                        navigator.clipboard
                          .writeText(window.location.href)
                          .then(() => {
                            alert("Copied link!");
                          });
                      }
                    }}
                  >
                    <IoShareOutline size={24} />
                  </Flex>
                  <Text
                    variant="meta"
                    style={{ marginTop: 4, textAlign: "center" }}
                  >
                    Share
                  </Text>
                </Flex>
                {author && (
                  <Flex
                    style={{
                      flex: 1,
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Flex
                      style={{
                        height: 50,
                        width: 50,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 100,
                        background: "rgba(0,0,0,0.05)",
                        color: "#383838",
                        border: "0.5px solid lightgray",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        logEvent(analytics, "visit_profile_end_of_story");
                        window.open(`/profile/${author}`, "_self");
                      }}
                    >
                      <IoPersonOutline size={24} />
                    </Flex>
                    <Text
                      variant="meta"
                      style={{ marginTop: 4, textAlign: "center" }}
                    >
                      More from this author
                    </Text>
                  </Flex>
                )}
                <Flex
                  style={{
                    flex: 1,
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <StoryOverflowButton
                    storyTitle={title}
                    storyCharacters={storyCharacters}
                    title={
                      <Flex
                        style={{
                          height: 50,
                          width: 50,
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 100,
                          background: "rgba(0,0,0,0.05)",
                          color: "#383838",
                          border: "0.5px solid lightgray",
                          cursor: "pointer",
                        }}
                      >
                        <IoEllipsisHorizontal size={24} />
                      </Flex>
                    }
                  />
                  <Text
                    variant="meta"
                    style={{ marginTop: 4, textAlign: "center" }}
                  >
                    Other
                  </Text>
                </Flex>
              </Flex>
            </Box>
          )}
          <Box
            style={{
              float: "left",
              clear: "both",
            }}
            ref={!showPreview ? bottomMessageRef : undefined}
          />
          {showPreview && (
            <Box
              style={{
                cursor: "pointer",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "100vh",
                background:
                  "linear-gradient(to bottom, transparent 0%, transparent 10%, rgba(255,255,255,0.6) 20%, white 65%, white 100%)",
              }}
              onClick={startReading}
            />
          )}
        </Container>
        {isDesktop && readMessages < messages.length && (
          <Container
            style={{
              borderTop: "1px solid lightgray",
              boxShadow: "0 -2px 4px rgba(0,0,0,0.05)",
              paddingTop: 14,
              paddingBottom: 14,
            }}
          >
            <ReaderActionBar
              storyInfo={{
                authorName: authorName || undefined,
                messageCount: messages.length,
                likeCount: likeCount,
                commentCount: commentCount,
              }}
              previewMode={showPreview}
              storyCharacters={storyCharacters}
              storyTitle={title}
              completed={readMessages >= messages.length}
              paused={paused}
              id={id}
              onClick={() => {
                if (showPreview) {
                  startReading();
                } else if (!loading && paused) {
                  sendNextMessage(readMessages + 1);
                } else {
                  setPaused(!paused);
                }

                if (paused) {
                  logEvent(analytics, "unpaused_autoplay");
                } else {
                  logEvent(analytics, "paused_autoplay");
                }
              }}
              didSelectDropdownOption={() => setPaused(true)}
            />
          </Container>
        )}
      </Flex>
    </Page>
  );
};

export default Read;
