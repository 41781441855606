import { ReactNode } from "react";

const Text = ({
  children,
  style,
  variant = "regular",
  ...restProps
}: {
  children: ReactNode;
  variant?: "meta" | "regular" | "title";
} & React.HTMLAttributes<HTMLParagraphElement>) => {
  var styleProps = {
    lineHeight: 1.2,
    fontSize: variant === "meta" ? 12 : variant === "title" ? 18 : 16,
    color: variant === "meta" ? "gray" : "black",
    fontWeight: variant === "title" ? "bold" : undefined,
    ...style,
  };
  return (
    <p {...restProps} style={styleProps}>
      {children}
    </p>
  );
};

export default Text;
