import { motion } from "framer-motion";
import Flex from "../../patterns/Flex";

const DOT_CONTAINER_HEIGHT = 8;
const DOT_SIZE = 6;

const loadingContainer = {
  display: "flex",
  height: DOT_CONTAINER_HEIGHT,
};

const loadingCircle = {
  width: DOT_SIZE,
  height: DOT_SIZE,
  marginLeft: 2,
  marginRight: 2,
  borderRadius: 100,
  display: "flex",
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: DOT_CONTAINER_HEIGHT - DOT_SIZE,
    opacity: 0.25,
  },
  end: {
    y: 0,
    opacity: 0.75,
  },
};

const DotLoader = ({ color = "gray" }: { color?: string }) => {
  return (
    <Flex style={{ alignItems: "center" }}>
      <motion.div
        style={loadingContainer}
        variants={loadingContainerVariants}
        initial="start"
        animate="end"
      >
        <motion.span
          style={{ ...loadingCircle, backgroundColor: color }}
          variants={loadingCircleVariants}
          transition={{
            duration: 0.35,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.span
          style={{ ...loadingCircle, backgroundColor: color }}
          variants={loadingCircleVariants}
          transition={{
            duration: 0.35,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
        <motion.span
          style={{ ...loadingCircle, backgroundColor: color }}
          variants={loadingCircleVariants}
          transition={{
            duration: 0.35,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut",
          }}
        />
      </motion.div>
    </Flex>
  );
};

export default DotLoader;
